.authenticator {
    overflow: hidden;
    display: flex;
    height: auto;
    min-height: calc(100vh - 70px);
    .login-formu {
        padding-top: 2rem;
        align-self: center;
        .bloque-login {
            display: flex;
            margin: 0 auto;
            justify-content: space-between;
            float: none;
            box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.03);
            padding: 0;
            background: $blanco;
            @media screen and (max-width: 1023px) {
                flex-wrap: wrap;
            }
            &:before {
                order: 2;
                content: "";
                border-right: 1px solid $gray3;
                @media screen and (max-width: 1023px) {
                    border-bottom: 1px solid $gray3;
                    border-right: 0;
                    width: 100%;
                }
            }
            .formulario {
                width: 40%;
                display: flex;
                justify-content: space-around;
                align-items: baseline;
                padding: 32px;
                box-sizing: border-box;
                margin: 0 auto;
                @media screen and (max-width: 1023px) {
                    width: 80%;
                }
                @media screen and (max-width: 767px) {
                    width: 100%;
                }
                .encabezado-login {
                    .titulo-encabezado {
                        border-bottom: 1px solid $gray3;
                        box-sizing: border-box;
                        font-size: 26px;
                        line-height: 1.5;
                        font-weight: bold;
                        padding-bottom: 0;
                        margin-bottom: 2rem;
                    }
                }
                p, li {
                    color: #555555;
                }
                .boton {
                    padding: 1rem 1.5rem;
                    margin: 0 0 1em 0;
                    width: 100%;
                    border-radius: 1px;
                    color: $colorAdmin;
                    font-size: 16px;
                    text-align: center;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: center;
                    border: 1px solid $colorAdmin;
                    &:hover {
                        background-color: $colorAdmin;
                        color: $blanco;
                    }
                }
                input[value="Ingresar"] {
                    background-color: $colorAdmin;
                    padding: 1rem 1.5rem;
                    margin: 1em 0;
                    width: 100%;
                    border-radius: 1px;
                    color: $blanco;
                    font-size: 16px;
                    text-align: center;
                    text-transform: uppercase;
                    border: 1px solid $colorAdmin;
                    /*&:hover {
                        background-color: transparent;
                        color: $colorAdmin;
                    }*/
                }
            }
            .formulario + .formulario {
                //border-left: 1px solid $gray3;
            }
            .content-left {
                order: 1;
            }
            .content-right {
                order: 3;
                form {
                    height: auto;
                    display: block;
                    label {
                        font-size: 1rem;
                    }
                    input[type="text"] {
                        height: 40px;
                    }
                }
                .divider {
                    text-align: center;
                    height: auto;
                    display: flex;
                    .content {
                        padding: 0 0.5em;
                    }
                    .filler {
                        border-bottom: 1px solid;
                        width: 50%;
                        box-sizing: border-box;
                        height: 20px;
                        .divider-line {
                            display: none;
                        }
                    }
                }
                .providers {
                    margin-top: 2rem;
                }
            }
        }
    }
}