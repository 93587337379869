#menuPrincipalTrigger, #menuMov{ display: none; }

@media only screen and (max-width: 920px){ 
	.sidr-class-back, 
	.sidr-class-js-generated,
	.sidr-class-user-data,
	.sidr-class-divider,
	.sidr-class-menu-icon,
	.sidr-class-hide-for-large-up, 
	#menuBreadcrumbs{display: none !important;}

	//////////////////////////////////////
	// BODY CON MENU ABIERTO //
	//////////////////////////////////////
	.contenido-competencia:before { @include eskinas; @include beforeAfter; background:transparent; opacity:0; pointer-events:none; z-index:110; transition:all .3s ease; }
	body.menuMov-open .contenido-competencia { pointer-events:none;
		&:before { opacity:.5; }
	}

	body.menuMov-open { overflow: hidden; max-height: 100vh; }
	.sidr-class-visually-hidden { display: none; }

	/////////////////////////////////////////
	// MENU PRINCIPAL > TRIGGER //
	/////////////////////////////////////////

	#menuPrincipalTrigger { display: block; position:fixed; top:1.3em; right:1.5rem; width:2.25em; height:1em; opacity:0; transition:all .5s $kortina; z-index:112; pointer-events:none;

		@media screen and (max-width:1320px) { opacity:1; pointer-events:auto; }

		#menuPrincipalTriggerInner { @include absolut(50%, 0); width:100%; height:2px; margin-top:-.05em; background:$colorHamburguesa; transition:all .5s linear;
			background: linear-gradient(
			to right,
			#fff 0%,
			#fff 30%,
			#000 30%,
			#000  100%   
			);
			&:before { @include absolut(-.5em); @include full; @include beforeAfter; background:$colorHamburguesa; transition:all .75s $kortina; transform-origin:0 0; }
			&:after { @include absolut(auto, 0, -.5em); @include full; @include beforeAfter; background:$colorHamburguesa; transition:all .75s $kortina; transform-origin:0 100%; }
		}

			// MENU ABIERTO // 
		body.menuMov-open & { top:1.5em;  right:1rem; 
			#menuPrincipalTriggerInner { background:transparent;
				&:before { height:105%; transform:rotateZ(45deg); background:$colorHamburguesa; top:-1rem; }
				&:after { height:105%; transform:rotateZ(-45deg); background:$colorHamburguesa; }
			}
		}
	}

	///////////////////////////////////////
	// MENU PRINCIPAL > MOVIL ////////////
	///////////////////////////////////////
	#header_info{ position: fixed; top: 0; left: 0; right: 0; z-index: 3; }
	.contenido-competencia{ margin-top: 3.4rem; }
	#menuMov.sidr { position:fixed; top:3.4rem; height:calc(100% - 3.4rem); width:100%; max-width:100%; overflow-x:hidden; overflow-y:auto; z-index:111; display: block; background: $azuloscuro;
		.sidr-class-not-click {
			> a[data-level="0"]{opacity:.5; 
				&:after, &:before{@include beforeAfter; content: "-"; display: inline-block; padding: 0 .25rem;}
			}
		}
		.sidr-class-has-dropdown {
			.sidr-class-dropdown {
				li:last-child {
					border: none;
				}
				.sidr-class-user-langs {
					display: flex;
				}
			}
		}
		.sidr-class-has-dropdown:last-of-type {
			li:nth-last-child(3) {
				width: 70%;
				float: left;
				height: 54px;
				@media screen and (max-width:480px) { width: 65%; }
				a {
					text-transform: initial;
				}
			}
			li:nth-last-child(2) {
				width: 30%;
				float: left;
				height: 54px;
				@media screen and (max-width:480px) { width: 35%; }
				a {
					text-transform: initial;
				}
			}
		}
		.sidr-class-right {
			li:last-child {
				border: none;
			}
			.sidr-class-user-langs {
				border: none;
				display: flex;
    			color: $azuloscuro;
				background: $azuloscuro;
			}
			.sidr-class-not-click {
				.sidr-class-dropdown {
					li {
						> a[data-id="2"] {
							position: relative;
						}
					}
					li:has(> a[data-id="2"]) {
						&:before {
							content: "\f5c7";
							font-family: uicons-regular-rounded !important;
							text-transform: none;
							line-height: 1;
							position: relative;
							top: 6px;
							color: #fff;
							float: left;
							margin: 1em 0;
							border-bottom: 1px solid #fff;
							display: block;
							width: calc(100% + 3rem);
							text-align: left;
							margin-left: -1.5rem;
							padding: 0 1.5rem 1.5rem;
						}
					}
				}
			}
		}
		&.left { left:-100%; right:auto; }
		&.right { left:auto; right:-100%; }
	 	.sidr-class-left{ li {padding: 0 1.5rem;} }
		.sidr-class-right li .sidr-class-dropdown li {padding: 0 1.5rem;} 

		/*menú ppal*/
		ul { @include sinLista; padding: 0;
			li { margin:0;  text-align: center; border-bottom: 1px solid $gray1; @include degradado;
				a, span {
					@include size(16,18); font-family: $headers-font; padding: 1rem 0; width:100%; display:inline-block; @include kaja; font-size:16px; line-height: 1.25; color: white; transition:all .3s linear; font-weight: 400; text-align: left; text-transform: uppercase; 
					&:hover {
						text-decoration: none;
					}
				}
				.sidr-class-JSlogout {
					&:before {
						content: "\f565";
						font-family: uicons-regular-rounded !important;
						text-transform: none;
						line-height: 1;
						margin-right: 0.5rem;
						position: relative; 
						top: 3px;
					}
				}
			}
	 
			&.sidr-class-links{ position:absolute; bottom:1rem; left: 1rem; display: flex;
				a{ margin-right: 1rem; }
			}
		
		}

	}

}