.contenedor-config {
    background-color: white;
    width: 100%;
    background: white;
    border: none;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: .75rem;
    margin: 0 0 2rem;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.03);
    box-sizing: border-box;
    button.tiny,
    .button.tiny {
        margin-left: 1.75rem;
    }
    .form_change {
        background-color: transparent;
        color: $warning;
        font-weight: 700;
    }
    fieldset .row {
        margin-bottom: 1rem;
    }
    fieldset legend {
        left: 0;
        top: 0;
    }
}

.companies-users-roles-fields-list li > div:first-child {
    @media screen and (max-width: 767px) {
        width: 100% !important;
        padding: 1rem;
        box-sizing: border-box;
        display: block !important;
    }
}

.companies-users-roles-fields-list li div .company-users-roles-remove-item {
    @media screen and (max-width: 767px) {
        margin-top: 0.5rem !important;
        left: -3.5rem;
        margin-left: -1rem !important;
    }
    @media screen and (max-width: 620px) {
        left: -2.5rem;
    }
}

.panel {
    border: none;
    &.panel_mini,
    &.panel_info {
        padding: 1rem;
        @include size(12, 13);
        p,
        ul,
        label {
            @include size(12, 13);
        }
    }
    &.panel_info {
        background-color: #fcfbf3;
    }
}

//////////////////////////////
////  CAMPOS FORMULARIOS /////
//////////////////////////////
.inputXxsmall {
    width: 12%!important;
    max-width: 80px!important;
}

.inputXsmall {
    width: 20%!important;
    max-width: 200px!important;
}

.inputSmall {
    width: 30%!important;
    max-width: 300px!important;
}

.inputMedium {
    width: 50%!important;
    max-width: 400px!important;
}

.inputLarge {
    width: 80%!important;
    max-width: 500px!important;
}

.inputXlarge {
    width: 100%!important;
    max-width: 600px!important;
}

.reveal-modal {
    .inputXxsmall,
    .inputXsmall,
    .inputSmall,
    .inputMedium,
    .inputLarge,
    .inputXlarge {
        input[type="text"],
        input[type="password"],
        input[type="email"],
        input[type="search"],
        input[type="url"],
        textarea,
        .dataTables_length label select {
            width: 100%;
            border: 1px solid $gray3;
            box-sizing: border-box;
            border-radius: 4px;
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 16px;
            line-height: 112%;
            color: $gray5;
        }
    }
}


/* filtros listados */

.JSformFilters {
    input.inputXxsmall {
        min-width: 50px;
    }
    input.inputXsmall {
        min-width: 100px;
    }
    input.inputSmall {
        min-width: 150px;
    }
    input.inputMedium {
        min-width: 350px;
    }
    input.inputLarge {
        min-width: 500px;
    }
}


/*deshabilitado*/

.disabled {
    color: #9da5ad;
}


/*texto ayuda*/

.help-text {
    color: #9da5ad;
    display: block;
    width: 100%;
    font-size: 11px;
    line-height: 1;
    margin: .5rem 0;
    text-transform: none;
}

input[type="text"][readonly].plaintext {
    display: block;
    width: 100%;
    padding-top: .375rem;
    padding-left: 0;
    padding-bottom: .375rem;
    margin-bottom: 0;
    line-height: 1.5;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

input[type="url"] {
    width: inherit;
}

.grupo-icono {
    position: relative;
    display: flex;
    .grupo-input-prepend {
        margin-right: -1px;
        display: flex;
        >.grupo-input-text {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            color: $blanco;
            background-color: $azuloscuro;
            &:hover {
                background: $colorAdmin;
                color: white;
                border-color: $colorAdmin;
                a {
                    color: white;
                }
            }
            a {
                color: $colorAdmin;
                .fi {
                    color: $blanco;
                }
            }
        }
    }
    .grupo-input-append {
        margin-left: -1px;
        display: flex;
        >.grupo-input-text {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            color: #495057;
            background-color: white;
            border: 1px solid #ccc;
            &:hover {
                background: $colorAdmin;
                color: white;
                border-color: $colorAdmin;
                a {
                    color: white;
                }
            }
            a {
                color: $colorAdmin;
                .fi {
                    color: $blanco;
                }
            }
        }
    }
    .grupo-input-text {
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: .375rem .75rem;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 0;
        text-align: center;
        white-space: nowrap;
        border-radius: .25rem;
        &:hover a .fa {
            color: white;
        }
    }
}

.grupo-input {
    input[type="color"] {
        min-height: 36px;
    }
    .colorpick-eyedropper-input-trigger {
        float: left;
        margin: .5rem 0;
    }
    select {
        display: block;
        min-height: 1.5rem;
        padding: .5rem 1rem;
        border: 1px solid #999;
        border-radius: 1px;
        width: 100%;
        @include size(12, 16);
        -moz-appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC') no-repeat 98% center;
    }
}

.form-group.row {
    margin-bottom: 1rem;
}


/*input file*/

.subir-archivo {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(38px + 2px);
    margin-bottom: 0;
    .subir-archivo-input {
        position: relative;
        z-index: 2;
        width: 100%;
        height: calc(38px + 2px);
        margin: 0;
        opacity: 0;
    }
    .subir-archivo-label {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;
        padding: .5rem .75rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        border: 1px solid #ccc;
        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 3;
            display: block;
            padding: .5rem .75rem;
            line-height: 1.5;
            color: #495057;
            content: "Seleccionar archivo";
            background-color: #e9ecef;
            border-left: 1px solid #ced4da;
            border-radius: 0 .25rem .25rem 0;
        }
    }
}


/*radios y checks*/

.grupo-checkbox,
.grupo-radio {
    p {
        @include size(12,
        13);
        line-height: 2;
        padding-right: .5rem;
        margin: 0;
    }
}

.custom-checkbox,
.custom-radio {
    position: relative;
    display: inline-block;
    padding-left: 1.5rem;
    margin-top: 1rem;
    label {
        margin-bottom: 0;
        line-height: 2.5;
        &:before {
            border-radius: .25rem;
            position: absolute;
            top: .5rem;
            left: 0;
            display: block;
            width: 1rem;
            height: 1rem;
            pointer-events: none;
            content: "";
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            background-color: #dee2e6;
        }
        &:after {
            position: absolute;
            top: .5rem;
            left: 0;
            display: block;
            width: 1rem;
            height: 1rem;
            content: "";
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
        }
    }
    input[type=checkbox],
    input[type=radio] {
        box-sizing: border-box;
        padding: 0;
        position: absolute;
        margin: 0;
        z-index: -1;
        opacity: 0;
        &:checked~label::before {
            background-color: $colorAdmin;
        }
        &:disabled~label::before {
            background-color: #f4f5f6;
        }
        &:checked~label::after {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
        }
    }
}

.custom-radio {
    label:before {
        border-radius: 50%;
    }
    input[type=radio]:checked~label::after {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
    }
}


/*FORMULARIO EN LINEA*/

.fila_grupo_campos {
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    .grupo_campos {
        display: inline-block;
        margin: 0 1rem 2rem 0;
        flex: auto;
        max-width: 500px;
        &.single-item {
            align-self: flex-end;
            .mensaje-feedback {
                left: 2rem;
            }
        }
        label {
            display: block;
        }
        input {
            display: block;
        }
        .help-text {
            position: absolute;
            width: auto;
        }
    }
}


/*validación*/

.required-fields {
    font-size: 11px;
    text-align: right;
    margin: 0;
    padding: 0;
    display: none;
}

.required-item {
    color: red;
    display: inline-block;
    font-size: 14px;
    line-height: 1;
}

// input:required:after{ content: "*"; display: block; width:10px; height:10px; font-size:12px; line-height:12px;color: red;}
.mensaje-feedback {
    font-size: 11px;
    text-align: left;
    display: block;
    /*position: absolute; bottom: -20px;*/
    display: none;
}

.no-validado {
    position: relative;
    .mensaje-feedback {
        color: red;
        display: block;
    }
    .help-text {
        display: none;
    }
    input:required {
        border: 1px solid red!important;
    }
    .grupo-input-prepend>.grupo-input-text {
        //background-color: transparent;
    }
    .grupo-input-append>.grupo-input-text {
        color: #cc0000;
        background-color: transparent;
        a {
            color: #cc0000;
        }
    }
    &.single-item {
        label {
            color: black;
        }
        a {
            color: black;
            font-weight: bold;
        }
    }
    .grupo-radio,
    .grupo-checkbox {
        p {
            color: #cc0000;
        }
    }
    input[type="text"],
    input[type="number"],
    input[type="email"],
    select {
        background-color: rgb(232, 240, 254) !important;
    }
    .select2-container--default .select2-selection--single,
    .select2-container--default .select2-selection--multiple {
        background-color: rgb(232, 240, 254) !important;
    }
    a.newRelObject {
        color: rgb(109, 109, 109)!important;
        background-color: rgb(232, 240, 254) !important;
    }
    .select2-container--default .select2-selection--multiple .select2-selection__choice {
        background-color: white;
    }
}

.filter_element {
    #filtro_Empresa_fechaAlta,
    #filtro_Empresa_fechaBaja {
        margin-right: 0.5rem; 
        @media screen and (max-width: 619px) {
            margin-bottom: 0.75rem;
        }
    }
}

.filter_element.no-validado {
    .form_change {
        font-weight: 700;
    }
}

.validado {
    position: relative;
    display: block;
    .mensaje-feedback {
        color: green;
    }
    .help-text {
        display: none;
    }
    input:required {
        border: 1px solid green!important;
    }
    .grupo-input-prepend>.grupo-input-text {
        border: 1px solid green;
        color: green;
        background-color: transparent;
    }
    .grupo-input-append>.grupo-input-text {
        border: 1px solid green;
        color: green;
        background-color: transparent;
        a {
            color: green;
        }
    }
    &.single-item {
        label {
            color: green;
        }
        a {
            color: green;
            font-weight: bold;
        }
    }
    .grupo-radio,
    .grupo-checkbox {
        p {
            color: green;
        }
    }
}

//////////////////////////////
///////  DROPZONE //////////// 
//////////////////////////////
.dropzone.dz-started .dz-message {
    display: block;
}

.dropzone {
    display: block;
    &.dz-clickable {
        &:before {
            @include beforeAfter;
            width: 220px;
            height: 40px;
            background: url(../../../public/images/ico-subir.png) no-repeat center top;
            background-size: auto 40px;
            display: block;
            margin: 1rem auto 0;
            text-align: center;
            opacity: .3;
            overflow: hidden;
        }
    }
    &.dropzone.dz-started.dz-message {
        display: block !important;
    }
    .dz-message {
        button.dz-button,
        button.dz-button:hover {
            background-color: transparent;
            color: #535353;
        }
    }
    .dz-preview {
        position: relative;
        background-color: #fcfcfc!important;
        display: block;
        vertical-align: top;
        margin: 0;
        border: 1px solid #e5e5e5;
        border-radius: 6px;
        padding: 0 15px;
        float: left;
        width: 100%;
        margin: 2rem 0 1rem;
        @media screen and (min-width: 920px) and (max-width: 1200px) {
            width: calc(25% - 10px);
            margin: 3rem 10px 0 0;
        }
        @media screen and (min-width:1200px) and (max-width: 1600px) {
            width: calc(33.33% - 10px);
            margin: 3rem 10px 0 0;
        }
        @media screen and (min-width: 1601px) {
            width: calc(25% - 10px);
            margin: 3rem 10px 0 0;
        }
        .dz-image {
            cursor: pointer;
            position: relative;
            min-height: 140px;
            max-width: 25%;
            left: 0;
            float: left;
            img,
            i {
                cursor: pointer;
                top: 50%;
                transform: translate(0, -50%);
                right: 0;
                left: 0;
                object-fit: cover;
                margin: 0 auto;
            }
            i:before {
                font-size: 60px;
                color: #bbbbbb;
            }
        }
        .dz-edit,
        .dz-remove,
        .filedownload {
            bottom: 0;
            margin: 0 0 1rem 1rem;
            color: $colorAdmin;
            &:hover {
                color: rgb(100, 100, 100);
            }
        }
        .dz-details {
            width: auto;
            margin: 1rem 0 3rem 2%;
            padding-bottom: 2rem;
            border-bottom: 1px dashed rgba(0, 0, 0, .15);
            display: block;
            max-width: 73%;
            overflow: hidden;
            overflow: hidden;
            span {
                padding: 0!important;
            }
            .dz-filename {
                font-size: 120%;
                text-align: left;
                white-space: pre-wrap;
            }
            .dz-size {
                float: left;
                max-width: auto !important;
                text-align: left;
                span {
                    display: block;
                }
            }
        }
    }
    +.clearfix {
        overflow: hidden;
        width: 100%;
        display: block;
    }
}

.dz-max-files-reached {
    pointer-events: none;
    border: 0;
    height: 0;
    .dz-message {
        display: none !important;
    }
    .dz-preview {
        pointer-events: initial;
    }
}

.dz-max-files-reached::before {
    display: none !important;
}

//////////////////////////////////////////// 
////////////////BOTONES SWITCH////////////// 
//////////////////////////////////////////// 
.switch-field {
    display: flex;
    margin-bottom: 36px;
    overflow: hidden;
    input {
        position: absolute !important;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        width: 1px;
        border: 0;
        overflow: hidden;
    }
    label {
        background-color: #e4e4e4;
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        line-height: 1;
        text-align: center;
        padding: 8px 16px;
        margin: 0 -1px 0 0;
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
        transition: all 0.1s ease-in-out;
    }
    input+label {
        margin: 0 0 0 -1px;
    }
    label:hover {
        cursor: pointer;
    }
    input:checked+label {
        background-color: $corporativo;
        color: white;
        box-shadow: none;
        margin: 0;
    }
    label:first-of-type {
        border-radius: 4px 0 0 4px;
        margin-left: 0;
    }
    label:last-of-type {
        border-radius: 0 4px 4px 0;
    }
    &.input-onoff {
        label:first-of-type {
            border-radius: 50px 0 0 50px;
        }
        label:last-of-type {
            border-radius: 0 50px 50px 0;
        }
        input:first-of-type:checked+label {
            background-color: #00b894;
        }
        input:last-of-type:checked+label {
            background-color: #d63031;
        }
        label.input-onoff:last-of-type {
            border-radius: 0 50px 50px 0;
        }
    }
}

//////////////////////////////////////////// 
////////////////RANGE SLIDER 1////////////// 
//////////////////////////////////////////// 
.slidecontainer {
    width: 100%;
    max-width: 768px;
    span {
        &.slide-from {
            float: left;
            display: inline-block;
            margin: 0 .5rem;
        }
        &.slide-to {
            float: right;
        }
    }
    .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 15px;
        border-radius: 5px;
        background: #d3d3d3;
        outline: none;
        opacity: 0.7;
        -webkit-transition: .2s;
        transition: opacity .2s;
        &:hover {
            opacity: 1;
        }
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: $corporativo;
            cursor: pointer;
        }
        &::-moz-range-thumb {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: $corporativo;
            cursor: pointer;
        }
    }
}

//////////////////////////////////////////// 
////////////////SWITCH v2////////////// 
//////////////////////////////////////////// 
.onoffswitch {
    position: relative;
    width: 80px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    input[type="checkbox"]+label,
    .onoffswitch input[type="radio"]+label {
        padding: 0;
    }
    input[type="checkbox"]+label,
    input[type="radio"]+label {
        cursor: pointer;
        display: block;
        margin-left: 0;
        margin-right: 0;
    }
    input[type="checkbox"]:disabled+label,
    input[type="radio"]:disabled+label {
        cursor: default;
    }
    .onoffswitch-checkbox {
        display: none;
    }
    .onoffswitch-label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border-radius: 20px;
    }
    .onoffswitch-inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.3s ease-in 0s;
    }
    .onoffswitch-inner:before,
    .onoffswitch-inner:after {
        display: block;
        float: left;
        width: 50%;
        height: 38px;
        padding: 0;
        line-height: 38px;
        font-size: 14px;
        color: white;
        font-family: Trebuchet, Arial, sans-serif;
        font-weight: bold;
        box-sizing: border-box;
    }
    .onoffswitch-inner:before {
        content: "SÍ";
        padding-left: 16px;
        background-color: #00B894;
        color: #fff;
    }
    .onoffswitch-inner:after {
        content: "NO";
        padding-right: 10px;
        background-color: #eee;
        color: #999;
        text-align: right;
    }
    .onoffswitch-switch {
        display: block;
        width: 28px;
        margin: 5px;
        background: #FFF;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 40px;
        border: 1px solid #ccc;
        border-radius: 20px;
        transition: all 0.3s ease-in 0s;
    }
    .onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
        margin-left: 0;
    }
    .onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
        right: 0;
    }
    .onoffswitch-checkbox:not(:checked)+.onoffswitch-label .onoffswitch-switch {
        left: 0;
    }
}

.reveal-modal .row .columns label.onoffswitch-label {
    padding: 0;
}

label.multiple-check {
    display: inline-block;
}

.materialonoff {
    position: relative;
    width: 55px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    input[type="checkbox"]+label,
    input[type="radio"]+label {
        display: block;
        margin: 0;
    }
    .materialonoff-checkbox {
        display: none;
    }
    .materialonoff-label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        height: 20px;
        padding: 0;
        line-height: 20px;
        border: 0px solid #FFF;
        border-radius: 30px;
        background-color: #9E9E9E;
    }
    .materialonoff-label:before {
        content: "";
        display: block;
        width: 30px;
        margin: -5px;
        background: #FFF;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 31px;
        border-radius: 30px;
        box-shadow: 0 6px 12px 0px #757575;
    }
    .materialonoff-checkbox:checked+.materialonoff-label,
    input[type="checkbox"]:checked+label,
    input[type="radio"]:checked+label {
        background-color: #42A5F5;
        margin: 0;
    }
    .materialonoff-checkbox:checked+.materialonoff-label,
    .materialonoff-checkbox:checked+.materialonoff-label:before {
        border-color: #42A5F5;
    }
    .materialonoff-checkbox:checked+.materialonoff-label .materialonoff-inner {
        margin-left: 0;
    }
    .materialonoff-checkbox:checked+.materialonoff-label:before {
        right: 0px;
        background-color: #2196F3;
        box-shadow: 3px 6px 18px 0px rgba(0, 0, 0, 0.2);
    }
}

////////////////////////////
///MENÚ TABS ALOJAMIENTOS///
////////////////////////////
.hidden {
    display: none;
}

div[id*='tab_Alojamiento'] #capaopciones .eliminarRegistros,
[id*='tab_Alojamiento'] #capaopciones .seleccionarTodos,
[id*='tab_Alojamiento'] #capaopciones .deseleccionarTodos,
[id*='tab_Alojamiento'] #capaopciones .exportarExcel {
    display: none !important;
}

form[data-rel-table="oTableAlojamiento"] fieldset legend {
    cursor: pointer;
}


/*form[data-rel-table="oTableAlojamiento"] fieldset legend:not(.activo)::after { display:inline-block; font-family: FontAwesome; font-size:16px; content: "\f01a"; font-weight: bold; width:15px; margin-left: 15px; }
        form[data-rel-table="oTableAlojamiento"] fieldset legend.activo::after { display:inline-block; font-family: FontAwesome; font-size:16px; content: "\f01b"; font-weight: bold; width:15px; margin-left: 15px; }*/

form[data-rel-table="oTableAlojamiento"] fieldset:nth-child(1) .filter_element:first-of-type .select2:first-of-type {
    min-width: unset !important;
    width: 100px !important;
}

form[data-rel-table="oTableAlojamiento"] fieldset:nth-child(3) .filter_element:first-of-type .select2:first-of-type {
    min-width: unset !important;
    width: 100px !important;
}

form[data-rel-table="oTableAlojamiento"] fieldset:nth-child(4) .filter_element .select2 {
    min-width: unset !important;
    width: 100px !important;
}

form[data-rel-table="oTableAlojamiento"] fieldset:nth-child(5) .filter_element .select2 {
    min-width: unset !important;
    width: 100px !important;
}

.alojamientos {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 767px) {
        flex-wrap: wrap;
        flex-direction: column;
    }
    .filtros-alojamientos {
        flex: 1;
        @media screen and (min-width: 768px) {
            flex: 20%;
            margin-right: -1px;
            max-width: 280px;
        }
        ul {
            display: flex;
            margin: 0 0 1rem;
            padding: 0;
            flex-direction: column;
            border-top: 1px solid #dcdcdc;
            @media screen and (min-width: 768px) {
                position: relative;
                z-index: 2;
            }
            li {
                margin-right: 1rem;
                border: 1px solid #dcdcdc;
                border-top: none;
                display: block;
                width: 100%;
                background-color: #F7F7F7;
                &.activo {
                    border-left: 4px solid $colorAdmin;
                    background-color: white;
                    @media screen and (min-width: 768px) {
                        border-right-color: transparent;
                    }
                }
                a {
                    text-transform: uppercase;
                    color: black;
                    display: flex;
                    padding: .5rem .75rem;
                    font-size: .85rem;
                    &:hover {
                        text-decoration: none;
                        background-color: #fff;
                    }
                    &::before {
                        counter-increment: section;
                        content: counter(section) ". ";
                        padding-right: .5rem;
                    }
                }
            }
        }
    }
    .alojamientos-formulario {
        flex: 1;
        border: 1px solid gainsboro;
        background-color: white;
        @media screen and (min-width: 768px) {
            flex: 70%;
            position: relative;
            z-index: 1;
        }
        @media screen and (max-width: 767px) {
            .formFilterOptions {
                top: 0;
            }
        }
        label {
            line-height: 20px;
        }
    }
    .tablaAlojamientos {
        padding: 2rem;
        margin: 0;
        fieldset {
            background-color: white;
            padding: 1rem 0;
            &:not(:last-of-type) {
                border-bottom: 1px dashed #dcdcdc;
            }
            legend {
                text-transform: uppercase;
                color: black;
                font-weight: normal;
                font-size: .85rem;
                font-family: $list-font-family;
                display: flex;
            }
        }
    }
}

//////////////////
///MODAL BORRAR///
//////////////////
.swal2-styled {
    &.swal2-confirm {
        background-color: $colorAdmin!important;
    }
    &.swal2-cancel {
        background-color: #6e6e6e!important;
    }
}

.swal2-html-container {
    font-size: 1em!important;
}

.swal2-icon {
    &.swal2-warning {
        border-color: #6e6e6e!important;
        color: #6e6e6e!important;
    }
}

.menutab {
    display: none;
}