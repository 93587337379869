.row {
    h1 {
        font-size: 1.2em;
        padding-bottom: 2rem;
    }
    h2 {
        font-size: 1em;
        padding-bottom: 2rem;
    }
}

.relative {
    position: relative;
}

.tabla {
    margin: 0 1rem;
}

.formFilterOptions {
    /*position: absolute;
    right: 0;
    top: -5em;*/
    text-align: right;
    a {
        margin-bottom: 0;
    }
}

// Filtros
form.JSnoChanges {
    font-size: .75em;
    //label { font-size:1em;}
}

form.JSformFilters {
    fieldset {
        margin: 0 0 1rem;
        legend {
            color: $gray5;
        }
    }
}

select,
filter_element select {
    min-width: 15rem;
    max-width: 50%;
}

select.JSselect2No,
filter_element select.JSselect2No {
    min-width: 0;
    max-width: none;
    border: 1px solid $gray3;
    border-radius: 4px;
    box-sizing: border-box;
}

select.JSselect2No,
filter_element select.JSselect2No {
    min-width: 0;
    max-width: none;
}

span.select2 { 
    @media screen and (max-width: $medio){ max-width: 100%; width: auto!important;}
    @media screen and (min-width: $medio){ min-width: 15rem; max-width: 500px; width: auto !important;}
}

input[type="text"].fYear {
    width: 3rem;
}

input[type="url"] {
    width: 30rem;
}

input.input-large {
    width: 30rem;
}

input.input-medium {
    width: 17rem;
}

input.input-small,
select.input-small {
    width: 3rem;
    min-width: 0;
}

input[type="number"].input-small {
    width: 4rem;
}

.panel {
    padding: 0.75rem;
    font-size: 0.75rem;
}

.progress {
    .meter {
        background-color: $fondocabecera;
    }
}



/*CONTENIDO COMPETENCIA*/
#header_breadcrumb{ font-size: 11px;  color: rgba($colorAdmin, .5);  padding: .5rem 0; margin-bottom: -1rem;
    + .alert-box{ margin: 1.5rem 0 0; }
}
.contenido-competencia{
    h1, h2, h3, h4, h5, h6{font-family: $headers-font; font-weight: 600;  margin: 1.5rem 0 2.5rem; padding:0; display: inline-block; overflow: hidden; color: $azuloscuro; border-bottom: 1px solid $gray3; box-sizing: border-box; width: 100%;
        @media screen and (min-width: 920px){margin-bottom: 3rem;}
    }
    h1{@include size(18,26); }
    button.secondary, .button.secondary{ @include size(14,16); padding: 0.5rem 1rem; background:$azuloscuro; border:1px solid $azuloscuro; color: $blanco; border-radius: 6px;
        &:hover{text-decoration: none; background-color: $gray3; color: white; border:1px solid $gray3; }
     }
    button, input[type="submit"], button.tiny{ background-color: $colorAdmin;padding: .75rem 1.5rem; margin: 1em 0; display: inline-block;  border-radius: 1px;  color: #fff;  font-size: 14px;  text-align: center;  text-transform: uppercase; border:none; 
            &:hover{ background-color: rgba(black, 1);}
            &.form_change_submit{ background-color: #2196f3; 
                &:hover{background-color: #207ec8; }
            }
        }

}

 

