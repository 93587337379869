////////////////////////////////
// Generales tabla 'DataTables'
table {
    .onoffswitch {
        max-width: 60px;
        .onoffswitch-switch {
            width: 18px;
        }
        input[type="checkbox"]:disabled+label,
        input[type="radio"]:disabled+label {
            max-width: 60px;
        }
        .onoffswitch-inner {
            &:before,
            &:after {
                height: 28px;
                line-height: 28px;
                font-size: 10px;
            }
        }
    }
}

table {
    img {
        border: none;
    }
    &.display {
        margin: 0 auto;
        width: 100%;
        clear: both;
        th.primero {
            border-right: none;
        }
        th.opciones {
            border-left: none;
        }
        tr.heading2 td {
            border-bottom: 1px solid #aaa;
            border-right: 1px solid white;
        }
    }
    thead tr th {
        padding: 1rem .75rem;
        background: $colorCabeceraTabla;
        cursor: default;
        /*Columna sin ordenamiento*/
        height: 25px;
        font-weight: 400;
        cursor: pointer;
        text-transform: uppercase;
        font-family: $headers-font;
    }
    tfoot th {
        padding: 3px 5px 3px 5px;
        cursor: default;
        /*Columna sin ordenamiento*/
        height: 20px;
        font-weight: bold;
        text-align: right;
    }
    td {
        padding: 3px 5px;
        vertical-align: middle;
        min-height: 20px;
        //switcher table
        >.onoffswitch {
            margin: 0 auto;
            .onoffswitch-inner:before {
                text-align: left;
            }
        }
    }
}

.dataTables_wrapper {
    position: relative;
    clear: both;
    zoom: 1;
    z-index: 0;
}

.dataTables_processing {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    width: 100vw;
    height: 100vh;
    border: none;
    text-align: center;
    background-color: #ffffffa3;
    .fa {
        font-size: 6rem !important;
    }
}

// Combo de numero de registros
.dataTables_length {
    height: auto;
    text-align: center;
    background-color: transparent;
    color: #787878;
    padding: 1rem 0;
    margin: 0;
    float: right;
    select {
        margin: 0 .5rem;
        padding: 0.5rem;
        display: inline;
        width: 55px;
        height: auto;
    }
    @media screen and (max-width:480px) {
        float: none;
        width: 100%;
        text-align: left;
    }
}

// Buscador de datos
.dataTables_filter {
    height: auto;
    @media screen and (max-width: 480px) {
        float: none;
        width: calc(180px + 0.5rem);
        padding: 0;
    }
    @media screen and (min-width: 481px) and (max-width:768px) {
        float: left;
        max-width: 70%;
        padding: 15px 0;
    }
    @media screen and (min-width:769px) {
        float: right;
        padding: 1rem;
    }
    text-align: left;
    background: transparent;
    margin: 0;
    label {
        position: relative;
        font-size: 16px;
    }
    input {
        margin: 0 .5rem;
        padding: .5rem;
        display: inline;
        width: 180px;
        height: auto;
        border: 1px solid $gray3;
        border-radius: 4px;
        color: $gray5;
        @media screen and (max-width: 768px) {
            margin: 0;
        }
    }
}

.dataTables_paginate {
    width: 100%;
    float: none;
    margin: 0;
    text-align: center;
    width: auto;
}

.dataTables_length,
.dataTables_filter,
.dataTables_info,
.dataTables_paginate {
    font-family: $body-font-family !important;
    @include size(12, 14);
}

.dataTables_info {
    float: left;
    text-align: center;
    margin-bottom: 1rem;
    padding: 5px;
    @include size(11, 12);
    color: $gray5;
    @media screen and (max-width:920px) {
        width: 100%;
    }
}


/* Pagination nested */

.paginate_disabled_previous,
.paginate_enabled_previous,
.paginate_disabled_next,
.paginate_enabled_next {
    height: 19px;
    width: 19px;
    margin-left: 3px;
    float: left;
}

// DataTables > Ordenacion
.sorting_asc {
    background: $gray4 url('../../images/sort_asc.png') no-repeat center right;
    color: black;
}

.sorting_desc {
    background: $gray4 url('../../images/sort_desc.png') no-repeat center right;
    color: black;
}

.sorting {
    background: $gray4 url('../../images/sort_both.png') no-repeat center right;
    color: #777;
}

.sorting_asc_disabled,
.sorting_disabled {
    background: $gray4 url('../../images/sort_asc_disabled.png') no-repeat center right;
    color: #777;
}

.sorting_desc_disabled {
    background: $gray4 url('../../images/sort_desc_disabled.png') no-repeat center right;
}

.sorting_disabled {
    background: $gray4;
}

// DataTables > clases de filas

tr.even,
tr.even td,
tr.even .tdOpciones {
    background-color: $blanco;
}

.thOpciones,
.tdOpciones {
    text-align: right;
    .fa {
        margin-left: .5rem;
    }
}

tr.even.row_selected td,
tr.even.row_selected td.tdOpciones,
tr.odd.row_selected td,
tr.odd.row_selected td.tdOpciones {
    background-color: $warning30 !important;
    @media screen and (max-width: 639px) {
        background-color: $gray2 !important;
    }
}

tbody tr.odd:not(.row_selected):hover td,
tbody tr.even:not(.row_selected):hover td {
    background-color: $gray2 !important;
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * Misc */

.top,
.bottom {
    padding: 15px;
    background-color: #F5F5F5;
    border: 1px solid #ccc;
}

.top .dataTables_info {
    float: none;
}

.clear {
    clear: both;
}

.dataTables_empty,
.centrado,
.dtCentrado {
    text-align: center;
}

tfoot input {
    margin: 0.5em 0;
    width: 100%;
    color: #444;
}

tfoot input.search_init {
    color: #999;
}

td.group {
    background-color: #d1cfd0;
    border-bottom: 2px solid #A19B9E;
    border-top: 2px solid #A19B9E;
}

td.details {
    background-color: #d1cfd0;
    border: 2px solid #A19B9E;
}

.example_alt_pagination div.dataTables_info {
    width: 40%;
}

.paging_full_numbers {
    height: auto;
    line-height: inherit;
    float: right;
    @media screen and (max-width: 920px) {
        width: 100%;
        display: flex;
        position: relative;
        >span {
            display: block;
            width: 100%;
            overflow: hidden;
            >a {
                display: inline-block;
            }
        }
    }
    a {
        color: $gray5;
        transition: all ease .5s;
        &.current {
            background: $azuloscuro;
            color: $blanco;
            border-radius: 4px;
        }
        &:hover {
            text-decoration: none;
        }
    }
    .paginate_button,
    .paginate_active {
        padding: .5rem .8rem;
        margin: 0 .5rem;
        cursor: pointer;
    }
    .paginate_button {
        background-color: transparent;
        color: $gray5;
        font-weight: 600;
        &:hover {
            background-color: $azuloscuro;
            color: $blanco;
            border-radius: 4px;
        }
        &.disabled {
            opacity: .5;
        }
        @media screen and (max-width: 920px) {
            &.first,
            &.last {
                position: absolute;
                top: 35px;
            }
            &.previous,
            &.next {
                display: block;
                overflow: hidden;
            }
            &.first {
                left: 0;
            }
            &.last {
                right: 0;
            }
        }
    }
    .paginate_active {
        background-color: $colorCabeceraTabla;
        color: #fff;
    }
}

table.KeyTable td {
    border: 3px solid transparent;
}

div.box {
    height: 100px;
    padding: 10px;
    overflow: auto;
    border: 1px solid #8080FF;
    background-color: #E5E5FF;
}


/*cambios dmcorporative*/

#capaFiltro {
    width: 200px;
    text-align: right;
    background: #f0f0f0;
    margin: 0 3px 5px 0;
    padding: 6px 15px 7px 15px;
    position: absolute;
    top: 40px;
    right: 481px;
    z-index: 5;
}

select#filtro {
    float: left;
    font-size: 11px;
    margin: 0 5px 0 0;
}


/*comenzamos la tabla*/

#tablaContenido {
    clear: both;
}

#tablaContenido_Marca {
    td>img {
        float: left;
        margin-right: 1rem;
    }
    td>img+br {
        display: none;
    }
    tr>td:nth-child(3) {
        font-size: 11px;
    }
}

.tablaContenido {
    background-color: white;
    font-family: $body-font-family;
    width: 100%;
    background: transparent;
    margin: 1rem 0;
    border-spacing: 0;
    border: none;
    @media screen and (min-width: 920px) {
        margin: 2rem 0;
    }
    th.sorting_asc+th.sorting_asc {
        color: $blanco;
        background: $gray4 url(../../images/sort_both.0bd34d64.png) no-repeat center right;
        +th.sorting_asc {
            color: $blanco;
            background: $gray4 url(../../images/sort_both.0bd34d64.png) no-repeat center right;
        }
    }
    #cabeceraTabla {
        th {
            font-family: 'Montserrat';
            font-weight: 700;
            font-size: 15px;
            line-height: 112%;
            color: $blanco;
        }
        th:first-child {
            border-radius: 4px 0px 0px 0px;
        }
        th:last-child {
            border-radius: 0px 4px 0px 0px;
        }
    }
    tbody {
        tr {
            td:first-child {
                text-transform: uppercase;
                font-weight: 600;
            }
            td {
                border-bottom: 1px solid $gray3;
                border-top: 1px solid $gray3;
                color: $gray5;
                font-size: 14px;
                line-height: 1.25;
            }
        }
    }
}

.tablaContenido tr th {
    &.sorting_desc,
    &.sorting_asc {
        font-weight: 900;
    }
}

table tr th,
table tr td,
table thead tr th,
table thead tr td {
    font-size: .85em;
}

//Tabla > Lista varios datos MN
.lista_relaciones {
    max-height: inherit;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    overflow-x: auto;
    font-size: 1em;
    li {
        white-space: nowrap;
        line-height: 1.5rem;
        display: inline-block;
        padding: 0 2px;
        &:after {
            content: " |"
        }
        &:last-child:after {
            content: ""
        }
    }
}

//Pantalla permisos de usuarios
table#tablaContenido_Permisos {
    border-spacing: 1px;
    background: #ddd;
    border: none;
    thead tr th {
        background: $colorCabeceraTabla;
        text-align: center;
    }
    tbody tr {
        th,
        td {
            background: #fff;
            .level1 {
                padding-left: 20px;
            }
            .level2 {
                padding-left: 40px;
            }
        }
        &:hover {
            th,
            td {
                background: #f1f1f1;
            }
        }
        td {
            text-align: center;
            a {
                display: block;
            }
        }
    }
}

// Estilos para pantallas menores a 1024px
@media only screen and (max-width: 1024px) {
    /*#tablaContenido_Congreso {
	 	tbody tr td:nth-child(4) a {display:block; width:16px; height:16px; text-indent: 50px; overflow:hidden;
	 		&:before {content:""; font-family:fontawesome; display:block;color: #000; font-size: 1.2em; text-indent: 0;}
	 		&:hover {@include opacity(0.5);}
	 	}
	 } */
}

// Opciones tabla
.bloqueSeleccion {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    @media screen and (max-width: $mini) {
        position: relative;
        margin: 0 0 2rem;
    }
}

#capaopciones {
    left: 0;
    margin: 0;
    position: absolute;
    top: 1rem;
    z-index: 1;
    @media screen and (max-width: $mini) {
        position: relative;
        margin: 1rem 0 2rem;
        border-radius: 0px 6px 6px 6px;
        background: #fff;
        padding: 0.75rem;
        //display: none;
    }
    a .fa {
        color: $azuloscuro;
    }
    a,
    .anadirRegistro,
    .eliminarRegistros,
    .seleccionarTodos,
    .deseleccionarTodos {
        position: relative;
        width: auto;
        min-width: 40px;
        height: 40px;
        color: $azuloscuro;
        font-size: 16px;
        transition: all .25s ease;
        padding: 3px 0.5em;
        background: $blanco;
        border-radius: 4px;
        text-align: center;
        margin-right: .5rem;
        display: inline-block;
        @media screen and (max-width: $mini) {
            text-decoration: none;
            color: $gray5 !important;
            width: 100%;
            text-align: left;
            i {
                color: $blanco !important;
                background: $azuloscuro;
                padding: 0.5rem;
                border-radius: 4px;
            }
            &:after {
                display: block;
                width: auto;
                opacity: 1;
            }
        }
        i {
            &:before {
                line-height: 2;
            }
        }
        @media screen and (max-width: $mini) {
            &:after {
                content: attr(title);
                @include size(11, 12);
                text-transform: uppercase;
                padding: .7rem 0 0 .5rem;
                min-width: 120px;
                position: absolute;
                top: 0;
                right: 0;
                left: 2rem;
                bottom: 0;
                opacity: 0;
                transition: opacity .5s ease;
                @media screen and (max-width: $mini) {
                    opacity: 1;
                    left: 2.5rem;
                }
            }
            &:hover {
                width: 190px;
                text-align: left;
                &:after {
                    display: block;
                    width: auto;
                    opacity: 1;
                }
            }
        }
        /*&:after {
            content: attr(title);
            @include size(11,
            12);
            text-transform: uppercase;
            padding: .7rem 0 0 .5rem;
            min-width: 120px;
            position: absolute;
            top: 0;
            right: 0;
            left: 2rem;
            bottom: 0;
            opacity: 0;
            transition: opacity .5s ease;
            @media screen and (max-width: $mini) {
                opacity: 1;
                left: 2.5rem;
            }
        }*/
        &:hover {
            text-decoration: none;
            color: $blanco !important;
            /*width: 190px;
            text-align: left;*/
            background-color: $azuloscuro;
            i {
                color: $blanco !important;
            }
            /*&:after {
                display: block;
                width: auto;
                opacity: 1;
            }*/
        }
    }
    a.qliksense:hover {
        width: 250px;
    }
}

.tabs-content {
    margin-top: 0.35rem;
}

tbody td.tdOpciones a {
    color: $gray5;
    font-size: 16px;
    line-height: 1.25;
    padding: 0 0.2em;
    transition: all 0.3s ease-in-out 0s;
    padding: 0.25rem 0.5rem !important;
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.tdOpciones a::after {
    display: block;
    margin-left: .35rem;
    content: attr(title);
    font-size: 14px;
    line-height: 1.25;
    margin-top: -4px;
}

// DataTables Responsive
table.dataTable.dtr-inline.collapsed tbody td:first-child,
table.dataTable.dtr-inline.collapsed tbody th:first-child {
    position: relative;
    padding-left: 40px;
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    color: #464C56;
}

table.dataTable.dtr-inline.collapsed tbody td:first-child:before,
table.dataTable.dtr-inline.collapsed tbody th:first-child:before {
    top: 13px;
    left: 12px;
    height: 16px;
    width: 16px;
    display: block;
    position: absolute;
    color: white;
    border: 2px solid $warning;
    border-radius: 16px;
    text-align: center;
    line-height: 16px;
    box-shadow: none;
    box-sizing: content-box;
    content: '+';
    background-color: $warning;
}

table.dataTable.dtr-inline.collapsed tbody td:first-child.dataTables_empty:before,
table.dataTable.dtr-inline.collapsed tbody th:first-child.dataTables_empty:before {
    display: none;
}

table.dataTable.dtr-inline.collapsed tbody tr.parent td:first-child:before,
table.dataTable.dtr-inline.collapsed tbody tr.parent th:first-child:before {
    content: '_';
    background-color: $gray3;
    border-color: $gray3;
    line-height: 0.35;
}

table.dataTable.dtr-inline.collapsed tbody tr.child td:before {
    display: none;
}

table.dataTable.dtr-column tbody td.control,
table.dataTable.dtr-column tbody th.control {
    position: relative;
    cursor: pointer;
}

table.dataTable.dtr-column tbody td.control:before,
table.dataTable.dtr-column tbody th.control:before {
    top: 50%;
    left: 50%;
    height: 16px;
    width: 16px;
    margin-top: -10px;
    margin-left: -10px;
    display: block;
    position: absolute;
    color: white;
    border: 2px solid white;
    border-radius: 16px;
    text-align: center;
    line-height: 14px;
    box-shadow: 0 0 3px #444;
    box-sizing: content-box;
    content: '+';
    background-color: #31b131;
}

table.dataTable.dtr-column tbody tr.parent td.control:before,
table.dataTable.dtr-column tbody tr.parent th.control:before {
    content: '-';
    background-color: #d33333;
}

table.dataTable tr.child {
    padding: 0.5em 1em;
}

table.dataTable tr.child:hover {
    background: transparent !important;
}

table.dataTable tr.child ul {
    /*display: inline-block;*/
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 1em;
}

table.dataTable tr.child ul li {
    border-bottom: 1px solid #efefef;
    padding: 0.5em 0;
    white-space: nowrap;
}

table.dataTable tr.child ul li:first-child {
    padding-top: 0;
}

table.dataTable tr.child ul li:last-child {
    border-bottom: none;
}

table.dataTable tr.child span.dtr-title {
    display: inline-block;
    min-width: 75px;
    font-weight: bold;
    text-transform: initial;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    line-height: 112%;
    color: $gray5;
}

table.dataTable tr.child span.dtr-data {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    line-height: 112%;
    color: $gray5;
    text-transform: initial;
}

table.dataTable tr.child span.dtr-data ul li {
    width: auto;
    float: left;
    padding: 0 .4em 0 .4em;
    border: none;
    border-right: solid 1px #cdcdcd;
    &:last-child {
        border: none;
    }
}

.tdCheckbox {
    width: 1.5rem;
    .JScheckboxAll {
        margin-bottom: 0;
    }
}


/*FILTROS*/

fieldset {
    border: none;
    margin: 0 1rem 2rem;
    padding: 0.5rem 0.75rem;
    background: $blanco;
    border-radius: 4px;
    @media screen and (min-width: 920px) {
        padding: .5rem .75rem;
    }
    @media screen and (max-width: 767px) {
        padding: 1rem .5rem;
        margin: 0 1rem 1rem;
    }
    background-color: linear-gradient(45deg,
    #0000000a,
    #00000003);
    .filter_element {
        //overflow: hidden;
    }
    label {
        cursor: auto;
        font-size: 13px;
        line-height: 18px;
        margin: 0 0 0.25rem 0;
        padding: 0 .5rem 0 0;
        color: $gray5;
        font-weight: 600;
    }
    input[type="checkbox"]+label,
    input[type="radio"]+label {
        cursor: pointer;
    }
    input[type="text"],
    input[type="password"],
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="month"],
    input[type="week"],
    input[type="email"],
    input[type="number"],
    input[type="search"],
    input[type="tel"],
    input[type="time"],
    input[type="url"],
    input[type="color"],
    textarea,
    select {
        padding: .5rem 0.75rem;
        max-width: 500px;
        min-height: 38px;
    }
    .fdatepicker:after {
        display: block;
        content: "\f073";
        font-family: fontawesome;
    }
    .select2-container .select2-selection--single,
    select {
        height: auto;
        padding: 0.25rem .5rem;
        border-radius: 4px;
    }
    legend {
        background-color: transparent;
        color: $azuloscuro;
        font-size: 15px;
        line-height: 1.25;
        font-weight: 700;
        display: block;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        font-family: "Montserrat", sans-serif;
        position: relative;
        left: -0.5rem;
        top: -1rem;
    }
    &.translations {
        padding: .5rem 0;
        legend {
            padding-top: 1rem;
            @include size(12,
            13);
            text-transform: none;
            font-weight: normal;
            color: #4d4d4d;
            font-style: normal;
            font-family: $body-font-family;
        }
        .columns {
            display: inline-block;
            &.input-trans {
                margin-bottom: .5rem;
            }
            input,
            textarea {
                width: 100%;
                max-width: 1000px;
            }
        }
    }
    .form_change {
        background-color: transparent;
        color: #dd4b39;
        font-weight: 400;
    }
    +.formFilterOptions {
        /*position: absolute;
        top: -3rem;
        right: 0;*/
        text-align: right;
        @media screen and (max-width: 920px) {
            text-align: left;
        }
        a.secondary {
            background-color: white;
        }
        a:nth-child(2) {
            background-color: transparent !important;
            color: $azuloscuro !important;
            &:hover {
                color: $gray3 !important;
            }
        }
    }
    .filter_element input,
    .filter_element select.JSselect2No {
        width: auto;
        min-width: 70px;
        min-height: 34px;
        border-radius: 4px;
    }
    .filter_element select.JSselect2No {
        //float: left;
        margin-right: .5rem;
    }
    .select2-selection--multiple,
    .select2-selection--multiple ul,
    .select2-selection--multiple ul li {
        //max-height: 34px;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow {
        height: 26px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGCAYAAAD68A/GAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABzSURBVHgBbcyxDYAgFATQw1jZ6CiuYO8OMoK1FFT2OgFxAhND7woOZIL8HyJIvORIuDwAKHpvMPrm0XEr+LzLE5VvignRrqyha8mjcyuEMAF3qMNjoPVdiIj3h+mQjB2usHokJOZ++8IUcyL6D2Flh3x+AHyOIT+6xNhOAAAAAElFTkSuQmCC);
        background-position: 50% center;
        background-repeat: no-repeat;
    }
    .select2-container .select2-search--inline .select2-search__field {
        margin-top: 0;
        @include size(12,
        13);
    }
}

fieldset.maps {
    .filter_element {
        overflow: visible;
    }
}

.filter_element {
    &.form_change {
        background-color: transparent;
        padding: 0;
        label,
        label.form_change {
            font-weight: bold;
            color: $warning;
        }
        input,
        select {
            &.form_change {
                border-color: black;
                background-color: white;
                border-radius: 4px;
                box-shadow: 0px 0px 0px 4px $warning30;
                box-sizing: border-box;
                font-weight: 600;
            }
        }
        .select2-container--default .select2-selection--single,
        .select2-container--default .select2-selection--multiple {
            border-radius: 4px;
            box-shadow: 0px 0px 0px 4px $warning30;
            box-sizing: border-box;
            font-weight: 600;
        }
    }
}

fieldset .form_change {
    color: $gray5;
}

.color-grey {
    color: #777;
}
