// Foundation by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source
@use "sass:math";
@import "global";
@import "grid";
//
// @variables
//
$include-html-tabs-classes: $include-html-classes !default;
$tabs-navigation-padding: rem-calc(16) !default;
$tabs-navigation-bg-color: $silver !default;
$tabs-navigation-active-bg-color: $white !default;
$tabs-navigation-hover-bg-color: scale-color($tabs-navigation-bg-color, $lightness: -6%) !default;
$tabs-navigation-font-color: $jet !default;
$tabs-navigation-active-font-color: $tabs-navigation-font-color !default;
$tabs-navigation-font-size: rem-calc(16) !default;
$tabs-navigation-font-family: $body-font-family !default;
$tabs-content-margin-bottom: rem-calc(24) !default;
//$tabs-content-padding: ($column-gutter/2) !default;
$tabs-content-padding: (math.div($column-gutter, 2)) !default;
$tabs-vertical-navigation-margin-bottom: 1.25rem !default;
@include exports("tab") {
    @if $include-html-tabs-classes {
        .tabs {
            @include clearfix;
            margin-bottom: 0 !important;
            margin-left: 0;
            dd,
            .tab-title {
                position: relative;
                margin-bottom: 0 !important;
                list-style: none;
                float: $default-float;
                >a {
                    display: block;
                    background: {
                        color: $tabs-navigation-bg-color;
                    }
                    color: $tabs-navigation-font-color;
                    padding: $tabs-navigation-padding $tabs-navigation-padding * 2;
                    font-family: $tabs-navigation-font-family;
                    font-size: $tabs-navigation-font-size;
                    &:hover {
                        background: {
                            color: $tabs-navigation-hover-bg-color;
                        }
                    }
                    &:focus {
                        outline: none;
                    }
                }
                &.active a {
                    background: {
                        color: $tabs-navigation-active-bg-color;
                    }
                    color:$tabs-navigation-active-font-color;
                }
            }
            &.radius {
                dd:first-child,
                .tab:first-child {
                    a {
                        @include side-radius($default-float, $global-radius);
                    }
                }
                dd:last-child,
                .tab:last-child {
                    a {
                        @include side-radius($opposite-direction, $global-radius);
                    }
                }
            }
            &.vertical {
                dd,
                .tab-title {
                    position: inherit;
                    float: none;
                    display: block;
                    top: auto;
                }
            }
        }
        .tabs-content {
            @include clearfix;
            margin-bottom: $tabs-content-margin-bottom;
            width: 100%;
            >.content {
                display: none;
                float: $default-float;
                padding: $tabs-content-padding 0;
                width: 100%;
                &.active {
                    display: block;
                    float: none;
                }
                &.contained {
                    padding: $tabs-content-padding;
                }
            }
            &.vertical {
                display: block;
                >.content {
                    padding: 0 $tabs-content-padding;
                }
            }
        }
        @media #{$medium-up} {
            .tabs {
                &.vertical {
                    width: 20%;
                    max-width: 20%;
                    float: $default-float;
                    margin: 0 0 $tabs-vertical-navigation-margin-bottom;
                }
            }
            .tabs-content {
                &.vertical {
                    width: 80%;
                    max-width: 80%;
                    float: $default-float;
                    margin-#{$default-float}: -1px;
                    padding-#{$default-float}: 1rem;
                }
            }
        }
        .no-js {
            .tabs-content>.content {
                display: block;
                float: none;
            }
        }
    }
}