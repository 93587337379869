    // Colores generales
    //Color incial base: #00acd0 // #008aa6
    $fondocabecera: #fed100;
    $secundariocabecera: #f6d126;
    $logoinvert: grayscale(100%) brightness(500%) sepia(0%) saturate(100%) contrast(1);
    $colortxtcabecera: #383838;
    $colorCabeceraTabla: #fff; // #5893d5
    $colorCabeceraTablaAct: #fcfcfc;
    $colorSelect2: $fondocabecera; // #5893d5
    $CalendarioCabbgcolor: $fondocabecera; // #5893d5
    $BotonesFondo: $fondocabecera; // #5893d5
    $BotonesFondoSecondary: #b9b9b9; // #5893d5
    $selectedTableRow: #fefee1 !important;
    $hoverTableRow: rgba(255, 247, 220, 0.4);
    $colorAlertBg: #B22222;
    $colorAlertText: white;
    $colorAdmin: #074C71;
    $colorChange: #f39c12;
    //Colores comunes
    $blanco: #FFFFFF;
    $gray1: #F2F4F7;
    $gray2: #E4E7EC;
    $gray3: #B7BECB;
    $gray4: #868A94;
    $gray5: #464C56;
    $warning: #F79009;
    $warning30: rgba(247, 144, 9, 0.3); 
    //Colores variable cliente
    $azuloscuro: #074C71;
    $azulmedio: #007BB3;
    @mixin degradado {
        background: linear-gradient(90deg, $azuloscuro 0%, $azulmedio 107.48%); 
    }
    //@import "estructura/foundation"; // foundation
    @import "estructura/globales"; // Estilos generales
    @import "estructura/configuracion"; // CONFIGURACION PROYECTO
    @import "estructura/foundationVO"; // foundation
    @import "estructura/login"; // Login
    @import "estructura/cabecera"; // CABECERA
    @import "estructura/tablaDatos"; // TABLADATOS
    @import "estructura/contenidos"; // CONTENIDOS
    @import "estructura/select2"; // JS Select
    @import "estructura/modal"; // MODAL
    @import "estructura/fullcalendar"; // FULLCALENDAR
    @import "estructura/comunes"; // FULLCALENDAR
    @import "estructura/fullcalendar"; // FULLCALENDAR
    @import "estructura/menu.movil"; // movil
    @import "estructura/authentication"; // GOOGLE AUTHENTICATOR LOGIN
    @import '~font-awesome';
    @import url("https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css"); //UIcons
    //@import "//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"
    //dropzone.js