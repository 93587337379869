// css ventanas modales
.reveal-modal {
    padding: 0;
    border-radius: 4px;
    /*CERRAR MODAL*/
    .close-reveal-modal {
        position: absolute;
        right: 10px;
        top: 8px;
        width: 34px;
        height: 34px;
        text-indent: -800px;
        overflow: hidden;
        background: $azuloscuro;
        border-radius: 100%;
        &:hover {
            opacity: 1;
        }
        &:before,
        &:after {
            position: absolute;
            left: 16px;
            top: 8px;
            content: ' ';
            height: 20px;
            width: 2px;
            background-color: $blanco;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
        @media screen and (max-width: 767px) {
            background: transparent;
            right: 5px;
            top: 5px;
            &:before,
            &:after {
                background-color: $gray4;
            }
        }
    }
    .row {
        h2 {
            font-size: 1.1em;
            border-bottom: solid 1px #cdcdcd;
            padding: 0 0 .2em 0;
            margin: 0 0 1em 0;
        }
        .entity_form_row {
            display: inline-block;
            width: auto;
            vertical-align: top;
            margin-right: 1rem;
            margin-bottom: 1rem;
            margin-left: 1rem;
            @media screen and (max-width: 767px) {
                display: block;
                width: auto !important;
                max-width: 100% !important;
            }
        }
        .entity_form_label {
            width: auto;
            display: block;
            vertical-align: top;
            label {
                padding: 0.2rem 0 0 0;
            }
            .entity_form_info {
                margin-right: 0.3rem;
            }
        }
        .columns {
            padding: 0 1em;
            @media screen and (max-width: $movilmedio) {
                padding: 0;
            }
            label:not(.onoffswitch-label) {
                padding: 0 0 8px 0;
                font-family: 'Roboto';
                font-weight: 500;
                font-size: 16px;
                line-height: 112%;
                color: $gray5;
                @media screen and (max-width: 619px) {
                    padding: 0 0 3px 0;
                }
            }
            label.form_change {
                font-weight: bold;
                color: $warning;
            }
        }
        .entity_form_widget {
            display: block;
            position: relative;
            .linkmap {
                position: absolute;
                color: #c0c0c0;
                display: inline-block;
                padding: 0rem .5rem;
                transition: all .3s ease-in-out;
                font-size: 1.2em;
                border: 1px solid #ccc;
                background-color: white;
                margin-left: -2px;
                line-height: 1.7;
                left: 100%;
                top: 0;
                &:hover {
                    color: black;
                }
            }
            a.newRelObject {
                color: $azuloscuro !important;
                display: inline-block;
                padding: 3px .5rem 0;
                transition: all .3s ease-in-out;
                font-size: 20px;
                border: 1px solid #ccc;
                background-color: white;
                line-height: 1.25;
                margin-left: 0;
                border-radius: 0 4px 4px 0;
                @media screen and (min-width: $movil) {
                    margin-left: -2px;
                }
                &:hover {
                    text-decoration: none;
                }
            }
            textarea {
                width: 100%;
            }
            textarea.inputXLarge {
                max-width: 98% !important;
                height: 8rem;
            }
            textarea::-webkit-scrollbar {
                width: 15px;
                background-color: $blanco;
            }
            textarea::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
            }
            textarea::-webkit-scrollbar-thumb {
                background-color: rgba(181, 186, 195, 0.5);
                border-radius: 25px;
                padding: 0 3px;
                border-right: 3px solid transparent;
                border-left: 3px solid transparent;
                background-clip: padding-box;
                width: 8px;
                height: 30px;
            }
            .entity_form_widget_readonly {
                font-size: 0.75rem;
                font-style: italic;
            }
        }
        //CSS Modal personas +1 incorporar persona
        .entity_relation_form_row {
            ul {
                li {
                    border-top: solid 1px #cdcdcd;
                    padding-top: .6em;
                    &:first-child {
                        border-top: none;
                        padding-top: inherit;
                    }
                    input,
                    select {
                        margin: 0;
                    }
                    .row {
                        vertical-align: top;
                    }
                }
            }
        }
        fieldset.entity_relation_form_row {
            background: #f1f1f1;
            padding-top: 0.5rem;
            position: relative;
            legend {
                background: none;
            }
            .legendModal {
                display: block;
                border-bottom: solid 1px #cdcdcd;
                margin: 0 0 1em 0;
                padding: 0 0 .5em 0;
                &:after {
                    display: block;
                    clear: both;
                    content: "";
                }
                label {
                    width: auto;
                    float: left;
                    font-weight: bold;
                    margin: 0;
                    padding: 0;
                }
                i {
                    position: absolute;
                    bottom: 2rem;
                    right: 1rem;
                    color: #97cd0d;
                    font-weight: bold;
                    transition: all .3s ease-in-out;
                    font-size: 1.2em;
                    &:hover {
                        color: #000;
                    }
                }
            }
            ul.fields-list {
                li {
                    span {
                        margin-bottom: 0;
                    }
                    a.newRelObject {
                        color: #c0c0c0;
                        display: inline-block;
                        padding: .3em;
                        transition: all .3s ease-in-out;
                        &:hover {
                            color: #97cd0d;
                        }
                    }
                    a.delete-relation {
                        color: #ca1b1b;
                        display: inline-block;
                        padding: 0 .3em;
                        margin: 0em;
                        transition: all 0.3s ease-in-out;
                        font-size: 1.5em;
                        &:hover {
                            color: #000;
                        }
                    }
                }
            }
        }
        #JSmodalForm_Empresa {
            fieldset:first-of-type {
                .inputXsmall {
                    input {
                        text-transform: uppercase;
                    }
                }
            }
        }
        .JSformFilters {
            padding: 2rem 1rem;
        }
        form {
            position: relative;
            display: block;
            width: auto;
            margin: 1rem 0 0;
            /*min-height: 3rem;*/
            background: none;
            .formFilterOptions {
                top: 0;
            }
            .form_button_row {
                position: relative;
                top: 0em;
                margin: 0 1rem;
                a,
                button {
                    margin-left: 0;
                    @include size(14, 16);
                    padding: 0.5rem 1rem;
                    background: $azuloscuro;
                    border: 1px solid $azuloscuro;
                    color: $blanco;
                    border-radius: 6px;
                    text-transform: none;
                    &:hover {
                        text-decoration: none;
                        background-color: $gray3;
                        color: white;
                        border: 1px solid $gray3;
                    }
                }
                button.form_change_submit {
                    background: $warning;
                    border: 1px solid $warning;
                    color: $blanco;
                    &:hover {
                        background-color: $blanco;
                        color: $warning;
                        .JSalertFormChange {
                            color: $warning;
                        }
                    }
                }
            }
        }
        .dataTables_filter label,
        .dataTables_length label {
            padding: 0;
        }
        .entityEditPaginator {
            @include size(14, 16);
            @media screen and (max-width: 619px) {
                display: block;
                position: relative;
                margin: 10px auto;
                width: 100%;
                text-align: center;
            }
            @media screen and (min-width: $movilmedio) {
                display: inline-block;
                position: absolute;
                right: 1rem;
                top: 1.5rem;
            }
            span {
                padding: 0 .5rem;
                display: inline-block;
            }
            .entityEditPaginatorCurrent {
                color: $colorAdmin;
            }
            a {
                color: $colorAdmin;
                padding: 0 0.5rem;
                margin: 0;
                line-height: 0;
                i.fi-rr-caret-right:before {
                    font-size: 12px;
                }
                i.fi-rr-caret-left:before {
                    font-size: 12px;
                }
            }
            * {
                vertical-align: middle;
            }
        }
    }
    .info_modal {
        @include size(12, 13);
        background: $gray1;
        border-radius: 4px 4px 0px 0px;
        padding: 1.25em 4em 1.25em 1.25em;
        margin: 0 auto;
        border-bottom: none;
        @media screen and (max-width: 920px) {
            //margin-top: 2.75rem; 
        }
        .field:not(:first-child) {
            b {
                border-left: 1px solid $gray3;
                box-sizing: border-box;
                padding-left: 0.25rem;
                margin-left: 0.25rem;
            }
            @media screen and (max-width: 767px) {
                margin-top: 0.5rem;
                display: block;
            }
        }
        div,
        .field div {
            display: inline-block;
            color: black;
        }
        .field {
            vertical-align: middle;
            border: none;
            padding: 0 0.5em;
            background: transparent;
            margin-bottom: inherit;
            font-size: 14px;
            line-height: 1.25;
            text-transform: uppercase;
            font-family: 'Montserrat';
            font-weight: 500;
            color: $gray5;
            b {
                vertical-align: top;
                font-weight: 700;
            }
        }
        .info_modal_title {
            font-size: 14px;
            line-height: 1.25;
            color: $azuloscuro;
            font-weight: bold;
            background: transparent;
            text-transform: uppercase;
        }
        .fieldExtra {
            background: transparent;
            a {
                color: $azuloscuro;
            }
        }
        .info_origin {
            display: block;
            border-bottom: none;
            margin-bottom: inherit;
        }
    }
    /*Campos de formulario*/
    input[type="text"],
    input[type="password"],
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="month"],
    input[type="week"],
    input[type="email"],
    input[type="number"],
    input[type="search"],
    input[type="tel"],
    input[type="time"],
    input[type="url"],
    input[type="color"],
    textarea,
    select {
        min-height: 34px;
        padding: .5rem .75rem;
    }
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="search"],
    input[type="url"],
    textarea,
    .dataTables_length label select {
        width: auto;
    }
    .select2-container .select2-selection--single,
    select {
        height: auto;
        padding: 0.27rem .5rem;
    }
    button,
    input[type="submit"],
    button.tiny {
        margin-left: 0 !important;
        @include size(14, 16);
        padding: 0.5rem 1rem;
        background: $azuloscuro;
        border: 1px solid $azuloscuro;
        color: $blanco;
        border-radius: 6px;
        text-transform: none;
        .JSalertFormChange {
            display: none;
        }
        .JSalertFormChange:first-child {
            display: initial;
        }
        &:hover {
            text-decoration: none;
            background-color: $gray3;
            color: white;
            border: 1px solid $gray3;
        }
        &.form_change_submit {
            background-color: $warning;
            border: 1px solid $warning;
            &:hover {
                color: $warning;
                background-color: $blanco;
                .JSalertFormChange {
                    color: $warning;
                }
            }
        }
    }
    .form_change {
        background-color: transparent;
        color: black;
        font-weight: 500;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow {
        height: 2rem;
        height: 28px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGCAYAAAD68A/GAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABzSURBVHgBbcyxDYAgFATQw1jZ6CiuYO8OMoK1FFT2OgFxAhND7woOZIL8HyJIvORIuDwAKHpvMPrm0XEr+LzLE5VvignRrqyha8mjcyuEMAF3qMNjoPVdiIj3h+mQjB2usHokJOZ++8IUcyL6D2Flh3x+AHyOIT+6xNhOAAAAAElFTkSuQmCC);
        background-position: 50% center;
        background-repeat: no-repeat;
    }
    .select2-container--default .select2-search--inline .select2-search__field {
        min-width: 5rem;
    }
    /*Tabs*/
    .tabs {
        margin-top: 1rem;
        //margin-bottom: -1px!important;
        border-bottom: none;
        display: flex;
        flex-wrap: wrap;
        @media screen and (max-width: 767px) {
            width: 100%;
            //margin-bottom: 1rem !important;
        }
        .tab-title {
            border-color: #e1e1e1;
            border-top: 4px solid transparent;
            &:not(.active) {
                margin-top: 6px;
                border: 1px solid $blanco;
                border-bottom: none;
                border-right: none;
                a {
                    font-weight: 500;
                    color: $gray5;
                    justify-content: start;
                    &:hover {
                        &:after {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
            &.active {
                border-top-width: 6px;
                border-radius: 4px 4px 0px 0px;
                border-style: solid;
                background: linear-gradient(white, white) padding-box, linear-gradient(to right, #074C71, #007BB3) border-box;
                border-right: 0;
                border-left: 0;
                a {
                    background-color: $gray1;
                    color: $azuloscuro;
                    font-weight: 600;
                    @media screen and (max-width: 619px) {
                        justify-content: start;
                    }
                }
                 ::after {
                    border-bottom: 1px solid #000;
                }
            }
            a {
                font-family: 'Montserrat';
                font-size: 12px;
                text-transform: uppercase;
                display: flex !important;
            }
            @media screen and (max-width: 619px) {
                background: $blanco !important;
                border: none !important;
                margin-top: 4px;
            }
        }
        li {
            @media screen and (max-width: 619px) {
                display: block;
                width: 100%;
            }
            @media screen and (max-width: 619px) {
                &.active {
                    background-color: #e1e1e1;
                    a {
                        background-color: #e1e1e1;
                    }
                }
            }
            a {
                text-transform: uppercase;
                @include size(12, 14);
                padding: .5rem 1rem;
                background: $gray2;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .tab-info {
            margin: 3px 0 0 5px;
            display: block;
            font-size: 11px;
            line-height: 16px;
            float: right;
            span {
                padding: 0;
                background-color: #adadad;
                color: white;
                border-radius: 100%;
                display: inline-block;
                min-width: 16px;
                height: 16px;
                line-height: 16px;
                text-align: center;
                font-weight: normal;
            }
        }
        .tabs dd>a,
        .tabs .tab-title>a {
            border: none;
            background-color: transparent;
        }
        /*SUBTABS*/
        &.subtabs {
            display: flex;
            align-items: center;
            margin: 0;
            //border-bottom: 1px solid #e1e1e1;
            padding-bottom: 3rem;
            width: 100%;
            box-sizing: border-box;
            @media screen and (max-width: 619px) {
                display: block;
                width: 100%;
                border: none;
            }
            .tab-title {
                border-top: 2px solid transparent;
                &:not(.active) {
                    border: 1px solid #fff;
                    margin-right: 0px;
                    margin-top: -1px;
                    @media screen and (max-width: 619px) {
                        margin-top: 0;
                    }
                    a {
                        text-transform: capitalize;
                        background-color: $gray2;
                        font-weight: 500;
                        font-size: 14px;
                        font-family: 'Montserrat';
                        color: $gray5;
                    }
                    .subtablink {
                        background-color: $gray1;
                    }
                    >a:hover {
                        background-color: #e1e1e1;
                    }
                }
                &.active {
                    background: $gray1 !important;
                    border-top: 1px solid #fff;
                    border-bottom: 1px solid #fff;
                    border-radius: 0;
                    margin-right: -1px;
                    margin-top: -1px;
                    a {
                        color: $colorAdmin;
                        text-transform: capitalize;
                        font-weight: 600;
                        font-size: 14px;
                        font-family: 'Montserrat';
                    }
                }
                @media screen and (max-width: 619px) {
                    border-top: 1px solid $blanco !important;
                    box-sizing: border-box;
                    .subtablink {
                        background: $gray2 !important;
                        padding: 1rem;
                    }
                }
            }
        }
    }
    .tabs::-webkit-scrollbar {
        height: 5px;
        background-color: $blanco;
    }
    .tabs::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255);
    }
    .tabs::-webkit-scrollbar-thumb {
        background-color: $azuloscuro;
        border-radius: 2px;
    }
    .tabs-content {
        border: none;
        border-top: 1px solid $blanco;
        margin: 0;
        padding: 0 0 1rem;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        background: $gray1;
        box-shadow: none;
        @media screen and (max-width: 767px) {
            padding: 0 0 1rem;
        }
        .bloqueSeleccion {
            padding: 0 0 0.9375rem;
            a,
            .anadirRegistro,
            .eliminarRegistros,
            .seleccionarTodos,
            .deseleccionarTodos {
                i {
                    &:before {
                        @media screen and (max-width: 768px) {
                            line-height: 1 !important;
                        }
                    }
                }
            }
        }
        /*SUBTABS*/
        &.subtabs-content {
            margin: 0;
            box-shadow: none;
            border: none;
            padding: 0;
            .tablaContenido {
                background-color: white;
                box-shadow: none;
                border: 1px solid #eaeaea;
            }
            fieldset {
                //margin: .5rem .25rem;
            }
            #capaopciones a {
                //background-color: white;
            }
            .paging_full_numbers .paginate_button {
                background-color: white;
            }
            .paging_full_numbers a.current,
            .paging_full_numbers a:hover {
                background-color: $colorAdmin;
            }
        }
    }
    button.secondary,
    .button.secondary {
        @include size(14, 16);
        padding: 0.5rem 1rem;
        background: transparent;
        border: 1px solid $colorAdmin;
        color: $colorAdmin;
        &:hover {
            text-decoration: none;
            background-color: $colorAdmin;
            color: white;
        }
    }
    /*MAPAS*/
    .mapContent {
        position: relative;
        width: 100%;
        height: 90%;
        top: 10%;
        @media screen and (max-width: 967px) {
            height: 85%;
            top: 15%;
        }
        .map {
            width: 100%;
            height: 100%;
        }
    }
    label.required:after {
        font-weight: normal;
        font-size: 13px;
        content: "*";
        color: red;
        margin-left: 0.1rem;
    }
    .ficherospermitidos {
        color: $colorAdmin;
        margin: 10px 0;
        font-size: calc(12px + (13 - 12) * (100vw - 400px) / (1920 - 400));
        padding: 0 2rem;
    }
    #contentModalInfoLogAction {
        padding: 2rem;
        br {
            display: block;
            content: "";
            margin-top: 10px;
        }
    }
    /*Ayudas labels
    label[for='formColor_color']{position:relative;}
    label[for='formColor_color']:after{display:block; content:"Ej.: #CCCCCC";position:absolute;z-index:9;top:0;right:0;color:#888;font-size:11px; top:.2rem;}
*/
    /*buscador mapa*/
    .buscadorMapa {
        position: absolute;
        padding: 0;
        box-sizing: border-box;
        top: 5px;
        left: 16px;
        width: calc(100vw - 100px);
        overflow: hidden;
        .mapsDirSearch {
            width: 500px;
            float: left;
            input[type="text"] {
                height: 42px;
                width: auto;
                display: inline-block;
                min-width: 360px;
                float: left;
                margin-right: 8px;
            }
            button {
                margin: 0;
                height: 42px;
                display: block;
                float: left;
            }
        }
        .contLatLong {
            margin-left: 520px;
            @media screen and (max-width: 987px) {
                margin-left: 0;
            }
            label {
                display: inline-block;
                margin: 0 8px 0 16px;
            }
            input {
                height: 40px;
                max-width: 200px;
                display: inline-block;
                background: rgba(255, 242, 175, 0.3);
                border-color: #e2df9a;
                &:focus {
                    background-color: white;
                    border-color: #ccc;
                }
            }
            button,
            input[type="submit"] {
                max-height: 42px;
                margin: 8px 2.25rem 0 8px;
                float: right;
            }
        }
    }
    .translations {
        legend {
            padding: 0 0 8px 0;
            font-family: 'Roboto';
            font-weight: 500;
            font-size: 16px;
            line-height: 112%;
            color: $gray5;
            margin-left: 1rem;
            @media screen and (max-width: 619px) {
                padding: 0 0 3px 0;
            }
        }
    }
}


/*CAPA MODAL MAX*/

.reveal-modal.xlarge,
#mapsModalsede {
    top: 1vh !important;
    left: 1vw !important;
    bottom: 1vh !important;
    right: 1vw !important;
    width: 98vw !important;
    height: 98vh !important;
    max-height: 98vh !important;
    position: fixed;
}

#mapsModalsede .close-reveal-modal {
    top: 5px;
    right: 16px;
    color: #000;
    z-index: 9;
}


/*DESPLEGABLE SUBTABS*/

.submenutab {
    position: relative;
    .subtotaltab {
        display: flex!important;
        align-items: center;
        justify-content: center;
        &:after {
            content: "\f078";
            display: block;
            width: 10px;
            height: 20px;
            font-family: fontawesome;
            margin-left: .75rem;
            color: $azuloscuro;
        }
    }
    .menutab {
        position: absolute;
        visibility: hidden;
        background-color: $gray2;
        .subtabs {
            flex-direction: column;
            margin: 0;
            padding: 1rem 0;
            min-width: 220px;
            align-items: flex-start;
            box-shadow: 0 3px 4px rgba(158, 158, 158, .5);
            background: $gray2;
            @media screen and (max-width: 619px) {
                background: $blanco;
            }
            li {
                width: 100%;
                border: none!important;
                a {
                    display: flex;
                    align-items: flex-start;
                }
            }
            .tab-title:hover {
                border-top-color: transparent;
                background-color: #f6f6f6;
            }
        }
    }
    &:hover {
        .menutab {
            visibility: visible;
            z-index: 9;
        }
    }
    &.active .subtotaltab:after {
        display: none;
    }
}