body {
    font-family: $body-font-family;
    font-size: 16px;
    background: $gray1;
    //max-width: 1800px; 
    margin: 0 auto;
}

// Cabecera general
#header_info {
    background-color: $blanco;
    color: $colortxtcabecera;
    padding: 0;    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    z-index: 2;
    position: relative;
    /* background: linear-gradient(154deg, $fondocabecera 0, $secundariocabecera 100%); */
    @media screen and (max-width: 920px) {
        #header_data {
            float: left;
        }
    }
    @media screen and (min-width: 921px) {
        #header_data {
            float: right;
        }
    }
    #header_title {
        font-weight: 400;
        font-size: 1.1em;
        line-height: 1.1em;
        margin-top: 1em;
        margin-right: 1rem;
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
    #header_breadcrumb {
        font-size: 12px;
        padding-bottom: 2px;
    }
    #header_logo {
        height: rem-calc(55);
        width: 130px;
        position: relative;
        margin-left: 0.9375rem;
        img {
            max-width: 130px;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
            /* filter: $logoinvert; */
        }
    }
    #header_user {
        height: rem-calc(55);
        text-align: right;
        padding-right: 10px;
        padding-top: rem-calc(10);
        font-size: 70%;
        span {
            display: block;
        }
    }
}

// Idiomas
.tabs_idiomas {
    ul.tabs .tab-title a {
        padding: 5px 10px;
        font-size: 80%;
    }
    .tabs-content {
        margin-bottom: 0px;
    }
    .tabs-content .content {
        padding-bottom: 0px;
    }
}

.idiomasCab {
    background: transparent;
    border: none;
    border-radius: 0;
    padding: 0;
    position: absolute;
    right: 1rem;
    top: 1.5rem;
    border: none;
    border-top: 0;
    color: white;
    text-transform: uppercase;
    a {
        color: white;
    }
}

//////////////////////////////////////////////////
//      LOCALIZAR ORIGEN
/////////////////////////////////////////////////
.row {
    max-width: none;
}

.inline {
    display: inline-block;
}

.rowFormDateTime select {
    width: auto;
}

#cargando {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
    background-color: #000;
    z-index: 2147483647;
    background-image: none;
    i.fa-spinner {
        color: white;
        font-size: 3rem;
        left: 50%;
        position: fixed;
        top: 50%;
        font-size: 6rem!important;
    }
}

div.error ul,
.entity_form_error ul {
    color: #dd4b39;
    font-size: 12px;
    list-style: none;
    margin: 0;
}

.panel.panel_mini {
    padding: 5px;
    font-size: 11px;
    p,
    ul {
        font-size: 11px;
        margin-bottom: 10px;
    }
}

.reveal-modal {
    overflow-y: auto;
}

.reveal-modal-bg {
    position: fixed;
}

.entity_show_label {
    width: 30%;
    display: inline-block;
}

.entity_show_value {
    width: 65%;
    display: inline-block;
}

legend {
    text-transform: capitalize;
}

.newRelObject {
    margin-left: 5px;
    vertical-align: top;
}

.newRelObject img {
    width: 16px;
}

select[multiple] {
    height: auto;
}

.entity_relation_form_row ul {
    list-style: none;
    margin: 0;
}

.entity_relation_form_row ul li .row {
    display: inline-block;
    margin-right: 10px;
}

.entity_relation_form_row legend label {
    display: inline-block;
}

.entity_relation_form_row legend img {
    width: 16px;
    vertical-align: sub;
}

.delete-relation {
    vertical-align: top;
}

.widthAuto {
    width: auto;
}

.filter_element {
    margin-right: 10px;
    margin-bottom: 8px;
    margin-left: 10px;
    margin-top: 8px;
    display: inline-block;
}


/*.filter_element label{
  margin-right: 10px;
}*/

.filter_element input,
.filter_element select,
.filter_element textarea {
    display: inline-block;
}

.filter_element input,
.filter_element textarea,
.filter_element select.JSselect2No {
    width: auto;
}


/*.filter_element .select2-container{
  margin-bottom: 0px;
}*/

.form_change {
    color: $colorAlertText;
    background-color: $colorAlertBg;
    /*padding-left: 0.2rem!important;
    padding-right: 0.2rem!important;
    display: inline-block;
    font-weight: bold;*/
}

.form_change_submit {
    color: $colorAlertText;
    background-color: $colorAlertBg;
    border-color: $colorAlertBg;
    font-weight: bold;
    .JSalertFormChange { 
        color: #fff;
        font-size: 1rem;
        margin-right: 0.5rem;
        display: none; 
    }
    .JSalertFormChange:first-child {
        display: initial;
    }
}

.top-bar {
    @include degradado;
    height: auto !important;
    li.user-langs {
        &:hover {
            background-color: $colorAdmin;
        }
        a {
            display: inline;
            background: none !important;
        }
    }
    .top-bar-section .not-click>a:first-of-type {
        cursor: default;
    }
    .top-bar-section a {
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        font-size: 12px;
    }
    @media screen and (min-width:641px) {
        .top-bar-section>ul>.divider,
        .top-bar-section>ul>[role="separator"] {
            height: 2.5rem;
        }
    }
    .top-bar-section .left li:not(.has-form) a:not(.button) { min-width: 120px; text-align: left; } 
    .top-bar-section .right li.has-dropdown > a { min-width: 60px; text-align: left; }
    .top-bar-section li:not(.has-form) a:not(.button) {
        line-height: 2.5rem;
        padding: 0 0.5rem;
        @include degradado;
        font-weight: 600;
        font-size: 12px;
        border-radius: 0 0 4px 4px;
        &:hover {
            text-decoration: none;
        }
    }
    .top-bar-section ul li,
    .top-bar-section .dropdown li:not(.has-form):not(.active)>a:not(.button) {
        @include degradado;
        border: none;
        color: #fff;
        &:hover {
            background: $azuloscuro;
            a {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }
        &:hover:not(.user-data):not(.user-langs) {
            background-color: $azuloscuro;
        }
        &.sep {
            border-bottom: 1px solid rgba(242, 244, 247, .6);
        }
    }
    .top-bar-section ul li {
        //background: $azulmedio;
        i {
            font-size: 16px;
            position: relative;
            top: 3px;
        }
    }
    .top-bar-section .has-dropdown>a:after {
        top: 1.2rem;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGCAYAAAD68A/GAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABzSURBVHgBbcyxDYAgFATQw1jZ6CiuYO8OMoK1FFT2OgFxAhND7woOZIL8HyJIvORIuDwAKHpvMPrm0XEr+LzLE5VvignRrqyha8mjcyuEMAF3qMNjoPVdiIj3h+mQjB2usHokJOZ++8IUcyL6D2Flh3x+AHyOIT+6xNhOAAAAAElFTkSuQmCC);
        background-position: 50% center;
        background-repeat: no-repeat;
        border: none;
        width: 10px;
        height: 6px;
        filter: brightness(0) invert(1);
    }
    .user-data {
        background: $fondocabecera;
        margin: 0;
        color: white;
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        border-top: 1px solid rgba(255, 255, 255, .1);
        padding: .75rem 1rem;
        &:hover {
            background-color: $azuloscuro !important;
        }
        p {
            font-size: 90%;
            margin: 0;
        }
    }
}