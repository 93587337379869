// Foundation by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source
@use "sass:math";
@import "global";
//
// Block Grid Variables
//
$include-html-block-grid-classes: $include-html-classes !default;
$include-xl-html-block-grid-classes: false !default;
// We use this to control the maximum number of block grid elements per row
$block-grid-elements: 12 !default;
$block-grid-default-spacing: rem-calc(20) !default;
$align-block-grid-to-grid: false !default;
@if $align-block-grid-to-grid {
    $block-grid-default-spacing: $column-gutter;
}

// Enables media queries for block-grid classes. Set to false if writing semantic HTML.
$block-grid-media-queries: true !default;
//
// Block Grid Mixins
//
// Create a custom block grid
//
// $per-row - # of items to display per row. Default: false.
// $spacing - # of ems to use as padding on each block item. Default: rem-calc(20).
// $include-spacing - Adds padding to our list item. Default: true.
// $base-style - Apply a base style to block grid. Default: true.
@mixin block-grid( $per-row:false, $spacing:$block-grid-default-spacing, $include-spacing:true, $base-style:true) {
    @if $base-style {
        display: block;
        padding: 0;
        @if $align-block-grid-to-grid {
            margin: 0;
        }
        @else {
            //margin: 0 (-$spacing/2);
            margin: 0 (math.div(-$spacing, 2));
        }
        @include clearfix;
        &>li {
            display: block;
            height: auto;
            float: $default-float;
            @if $include-spacing {
                //padding: 0 ($spacing/2) $spacing;
                padding: 0 (math.div($spacing, 2)) $spacing;
            }
        }
    }
    @if $per-row {
        &>li {
            //width: 100%/$per-row;
            width: math.div(100%, $per-row);
            @if $include-spacing {
                padding: 0 ($spacing/2) $spacing;
            }
            list-style: none;
            &:nth-of-type(1n) {
                clear: none;
            }
            &:nth-of-type(#{$per-row}n+1) {
                clear: both;
            }
            @if $align-block-grid-to-grid {
                @include block-grid-aligned($per-row, $spacing);
            }
        }
    }
}

@mixin block-grid-aligned($per-row, $spacing) {
    @for $i from 1 through $block-grid-elements {
        @if $per-row>=$i {
            $grid-column: '+' + $i;
            @if $per-row==$i {
                $grid-column: '';
            }
            &:nth-of-type(#{$per-row}n#{unquote($grid-column)}) {
                padding-left: ($spacing - (($spacing / $per-row) * ($per-row - ($i - 1))));
                padding-right: ($spacing - (($spacing / $per-row) * $i));
            }
        }
    }
}

// Generate presentational markup for block grid.
//
// $size - Name of class to use, i.e. "large" will generate .large-block-grid-1, .large-block-grid-2, etc.
@mixin block-grid-html-classes($size, $include-spacing) {
    @for $i from 1 through $block-grid-elements {
        .#{$size}-block-grid-#{($i)} {
            @include block-grid($i, $block-grid-default-spacing, $include-spacing, false);
        }
    }
}

@include exports("block-grid") {
    @if $include-html-block-grid-classes {
        [class*="block-grid-"] {
            @include block-grid;
        }
        @if $block-grid-media-queries {
            @media #{$small-up} {
                @include block-grid-html-classes($size: small, $include-spacing: false);
            }
            @media #{$medium-up} {
                @include block-grid-html-classes($size: medium, $include-spacing: false);
            }
            @media #{$large-up} {
                @include block-grid-html-classes($size: large, $include-spacing: false);
            }
            @if $include-xl-html-block-grid-classes {
                @media #{$xlarge-up} {
                    @include block-grid-html-classes($size: xlarge, $include-spacing: false);
                }
                @media #{$xxlarge-up} {
                    @include block-grid-html-classes($size: xxlarge, $include-spacing: false);
                }
            }
        }
    }
}