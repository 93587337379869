/////////////
// COLORES //
/////////////
$corporativo: #000;
$gris: #ccc;
$grisOscuro: #333333;
$grisClaro: #E9EAE9;
$grisMedio: #949494;
$dorado: #a07f6b;
$colorHamburguesa: $corporativo;

$success: #3adb76;
$warning: #F79009;
$alert: #cc4b37;

$hover: lighten($corporativo, 10%);
 

/////////////////
// DIMENSIONES //
/////////////////
$movilmini: 23.438rem;
$movil: 30rem;
$movilmedio: 38.750em;
$mini: 48rem;
$medio: 57.500em;
$normal: 80rem;
$ancho: 100rem;
$anchoMax: 100%;

/////////////
// FUENTES // 
/////////////
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

///////////
// GRID //
/////////

$medianil : 0.9375em;
$numero-columnas: 12;

$body-font-color: #222; 
$body-font-family: 'Roboto', sans-serif;
$body-font-weight: 400;
$headers-font: 'Montserrat', sans-serif;

/////////////////////// 
// ANIMACIONES //
///////////////////////

$bota: cubic-bezier(0.375, 1.495, 0.610, 0.780);
$kortina: cubic-bezier(0.6,0,0.4,1);

/////////////////////
// KEYFRAMES //
/////////////////////

@keyframes gira {
	from { transform:rotate(0deg); }
	to { transform:rotate(360deg); }
}

////////////
// MIXINS //
////////////

@mixin no { position:relative; height:0; }
@mixin alCentro($anchoMax:100%) { width:100%; max-width:$anchoMax; margin-left:auto; margin-right:auto; }
@mixin kaja($espacios:0) { box-sizing:border-box; @if $espacios not 0 { padding-left:$espacios; padding-right:$espacios; } }
@mixin rekt($medida:1em, $redondo:false) { width:$medida; height:$medida; display:inline-block; @if($redondo){ @include border-radius(50%); } }
@mixin beforeAfter($contenido:'') { content:$contenido; display:block; clear:both; }
@mixin full { width:100%; height:100%; display:block; }
@mixin miniReset { margin:0; padding:0; }
@mixin sinLista { @include miniReset; list-style-type:none; }
@mixin absolut($y:0, $x:0, $y2:auto, $x2:auto) { position:absolute; top:$y; left:$x; bottom:$y2; right:$x2; }
@mixin eskinas($margen:0) { @include absolut($margen, $margen, $margen, $margen); }
@mixin fondo($color:$colorPrincipal, $transparencia:.85, $zIndex:1) { @include absolut; @include full; @include beforeAfter; background:$color; @include opacity($transparencia); z-index:$zIndex; }
@mixin fontawesome($kontenido:'') { display:inline-block; font-family:'FontAwesome'; font-feature-settings:normal; font-kerning:auto; font-language-override:normal; font-size:inherit; font-size-adjust:none; font-stretch:normal; font-style:normal; font-synthesis:weight style; font-variant:normal; font-weight:normal; line-height:1; text-rendering:auto; content:$kontenido; }
@mixin size($fontMin: 16, $fontMax: 24, $screenMin: 400, $screenMax: 1920) {
  $fontMinPx: $fontMin * 1px;
  $screenMinPx: $screenMin * 1px;
  font-size: calc(#{$fontMinPx} + (#{$fontMax} - #{$fontMin}) * (100vw - #{$screenMinPx}) / (#{$screenMax} - #{$screenMin}));
}
@mixin nashville {
  &:before { @include eskinas; @include beforeAfter; background:rgba(247,176,153,.56); mix-blend-mode:darken; z-index:1; }
  &:after { @include eskinas; @include beforeAfter; background:rgba(0,70,150,.4); mix-blend-mode:lighten; z-index:2; }
  @supports (-ms-ime-align:auto) { &:before, &:after { display:none; } }
}
@mixin transition{transition: all .3s ease;}
@mixin flip{ transform: scaleX(-1); }
 
@mixin prop($propiedad, $min: 16, $max: 24, $screenMin: 400, $screenMax: 1920) {
  $minPx: $min * 1px;
  $screenMinPx: $screenMin * 1px;
  #{$propiedad}: calc(#{$minPx} + (#{$max} - #{$min}) * (100vw - #{$screenMinPx}) / (#{$screenMax} - #{$screenMin}));
}
 
@mixin gridContenido{ max-width: $ancho; margin: 0 auto; }


@mixin grid($grid-type, $args, $row-gap, $column-gap: $row-gap) {
  @supports (display: grid) {
    display: grid;
    #{$grid-type}: #{$args};
    grid-gap: ($row-gap) ($column-gap);
  }
}
/* empleo
@include grid('grid-template-rows', '1fr 2fr', 10px, 20px); -----filas
@include grid('grid-template-columns', 'repeat(3, auto)', 1rem, 1.5rem); ---- columnas*/


//////////////////
// GLOBALES //
/////////////////

* { -webkit-overflow-scrolling:touch;
	&:focus { outline:none; }
}
*, *:before, *:after, div {
  box-sizing: border-box;
}

html { box-sizing: border-box; scroll-behavior: smooth; }
body { font-family: $body-font-family; margin: 0; color: $body-font-color; font-weight: $body-font-weight; background-color: #fcfcfc; counter-reset: section; }
a, a:hover, a:visited { text-decoration:none; }
a:hover{ color: $corporativo; } 
img { max-width:100%; height: auto; }
ul{@include sinLista;}
///////////////
// CONCRETOS //
///////////////
.fa{font:normal normal normal 18px FontAwesome!important;}
.text-right{ text-align: right; }
.page div {position: relative; box-sizing: border-box; }
.alert-box{z-index: 999;}
.embed-container { position:relative; padding-bottom:56.25%; height:0; overflow:hidden; width:100%;
	iframe, object, embed { @include absolut; @include full; }
}

@keyframes fadein{
    0% { opacity:.1;   }
    100% { opacity: 1;  }
}
