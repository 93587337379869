//PÁGINA LOGIN
.login-cabecera {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.18);
    position: relative;
    z-index: 3;
    background: #fff;
    border: none;
    color: #515151;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    img {
        margin-right: 1rem;
        width: auto;
        height: auto;
        filter: invert(100%);
    }
    .login-content {
        padding: 0;
    }
}

.login {
    overflow: hidden;
    display: flex;
    height: calc(100vh - 70px);
    h2 {
        margin: 0 auto 2rem;
        text-align: center;
        color: $colorAdmin;
        @include size(18, 22);
        text-transform: uppercase;
    }
    .login-slider {
        display: none;
        .orbit-container {
            height: 100vh;
            .orbit-timer,
            .orbit-slide-number,
            .orbit-prev,
            .orbit-next {
                display: none!important;
            }
            .orbit-slides-container {
                height: 100vh!important;
                li {
                    height: 100vh;
                }
            }
        }
        @media screen and (min-width:920px) {
            display: block;
            flex: 1;
            h3 {
                color: white;
            }
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
            .login-slider-caption,
            .orbit-caption {
                position: absolute;
                z-index: 3;
                color: white;
                left: 0;
                bottom: 52%;
                right: 0;
                text-align: center;
                padding: 0 5%;
                line-height: 1;
                @include size(28, 30);
                background: transparent;
                text-shadow: 0 0 6px black;
            }
            &:before {
                display: block;
                position: absolute;
                height: 100%;
                width: 33.33%;
                bottom: 0;
                content: "";
                z-index: 1;
                background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
                background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
                background: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
                max-height: 50%;
            }
        }
    }
    .login-formu {
        flex: 2;
        align-self: center;
    }
    .login-pie {
        padding: 1rem;
        text-align: center;
    }
    .capacentro {
        text-align: center;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        padding: 4rem 3rem;
        position: relative;
        max-width: 50rem;
        background-color: white;
        box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.03);
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        form {
            max-width: 25rem;
            margin: 0 auto;
        }
        p {
            text-align: left;
        }
    }
    .login-form {
        .input-group {
            display: flex;
            border: 1px solid #e8e7e7;
            overflow: hidden;
        }
        .form-group {
            background-color: transparent;
            padding: 0;
            width: 100%;
            box-sizing: border-box;
            border-radius: 0;
            margin: 0 0 1rem;
            .input-group-addon {
                border-right: 0;
                width: 15%;
                padding: .9rem 0.75rem;
            }
            input {
                border: none;
                width: 85%;
                margin-left: 0;
                padding: 1rem 0.75rem;
            }
            .login-bottom-links a,
            input[type="checkbox"]+label,
            input[type="radio"]+label {
                font-size: 14px;
            }
        }
        input {
            @include size(14, 16);
        }
        .form-group-send button {
            background-color: $colorAdmin;
            padding: 1rem 1.5rem;
            margin: 0 0 1em 0;
            width: 100%;
            border-radius: 1px;
            color: #fff;
            font-size: 16px;
            text-align: center;
            text-transform: uppercase;
            &:hover {
                background-color: rgba(black, 1);
            }
            i {
                display: none;
            }
        }
        .login-bottom-links {
            overflow: hidden;
            a {
                display: inline-block;
                float: right;
                padding: 0.2rem 0;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                    color: black;
                }
            }
            .checkbox {
                text-align: left;
                float: left;
            }
        }
    }
    .user_change_password,
    .user_resetting_request {
        label {
            text-align: left;
            @include size(14, 16);
            &.form_change {
                color: #dd4b39;
                background-color: transparent;
                text-align: left;
                display: block;
                font-weight: 400;
            }
        }
        input {
            min-height: 42px;
            padding: .5rem;
        }
        input[type="submit"] {
            background-color: $colorAdmin;
            padding: 1rem 1.5rem;
            margin: 1em 0;
            width: 100%;
            border-radius: 1px;
            color: #fff;
            font-size: 16px;
            text-align: center;
            text-transform: uppercase;
            border: none;
            &:hover {
                background-color: rgba(black, 1);
            }
        }
        a.link {
            text-align: center;
            color: #555;
            font-size: .8em;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
                color: black;
            }
        }
    }
}

.user_change_password {
    max-width: 420px;
    background: white;
    overflow: hidden;
    padding: 2rem;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.03);
    margin: 0 auto;
    input {
        width: 100%;
        margin-left: 0;
        padding: 1rem 0.75rem;
    }
}

#change_password_form {
    .row {
        margin-bottom: 1rem;
    }
}
