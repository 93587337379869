// Foundation by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source
@use "sass:math";
@import "global";
@import "grid";
@import "buttons";
@import "forms";
//
// Top Bar Variables
//
$include-html-top-bar-classes: $include-html-classes !default;
// Background color for the top bar
$topbar-bg-color: $oil !default;
$topbar-bg: $topbar-bg-color !default;
// Height and margin
$topbar-height: rem-calc(30) !default;
$topbar-margin-bottom: 0 !default;
// Controlling the styles for the title in the top bar
$topbar-title-weight: $font-weight-normal !default;
$topbar-title-font-size: rem-calc(17) !default;
// Set the link colors and styles for top-level nav
$topbar-link-color: $white !default;
$topbar-link-color-hover: $white !default;
$topbar-link-color-active: $white !default;
$topbar-link-color-active-hover: $white !default;
$topbar-link-weight: $font-weight-normal !default;
$topbar-link-font-size: rem-calc(13) !default;
$topbar-link-hover-lightness: -10% !default; // Darken by 10%
$topbar-link-bg: $topbar-bg !default;
$topbar-link-bg-hover: $oil !default;
$topbar-link-bg-color-hover: $charcoal !default;
$topbar-link-bg-active: $primary-color !default;
$topbar-link-bg-active-hover: scale-color($primary-color, $lightness: -14%) !default;
$topbar-link-font-family: $body-font-family !default;
$topbar-link-text-transform: none !default;
//$topbar-link-padding: ($topbar-height / 3) !default;
$topbar-link-padding: (math.div($topbar-height, 3)) !default;
$topbar-back-link-size: rem-calc(18) !default;
$topbar-link-dropdown-padding: rem-calc(20) !default;
$topbar-button-font-size: .75rem !default;
$topbar-button-top: 7px !default;
// Style the top bar dropdown elements
$topbar-dropdown-bg: $oil !default;
$topbar-dropdown-link-color: $white !default;
$topbar-dropdown-link-color-hover: $topbar-link-color-hover !default;
$topbar-dropdown-link-bg: $oil !default;
$topbar-dropdown-link-bg-hover: $oil !default;
$topbar-dropdown-link-weight: $font-weight-normal !default;
$topbar-dropdown-toggle-size: 5px !default;
$topbar-dropdown-toggle-color: $white !default;
$topbar-dropdown-toggle-alpha: .4 !default;
$topbar-dropdown-label-color: $monsoon !default;
$topbar-dropdown-label-text-transform: uppercase !default;
$topbar-dropdown-label-font-weight: $font-weight-bold !default;
$topbar-dropdown-label-font-size: rem-calc(10) !default;
$topbar-dropdown-label-bg: $oil !default;
// Top menu icon styles
$topbar-menu-link-transform: uppercase !default;
$topbar-menu-link-font-size: rem-calc(13) !default;
$topbar-menu-link-weight: $font-weight-bold !default;
$topbar-menu-link-color: $white !default;
$topbar-menu-icon-color: $white !default;
$topbar-menu-link-color-toggled: $jumbo !default;
$topbar-menu-icon-color-toggled: $jumbo !default;
$topbar-menu-icon-position: $opposite-direction !default; // Change to $default-float for a left menu icon
// Transitions and breakpoint styles
$topbar-transition-speed: 300ms !default;
// Using rem-calc for the below breakpoint causes issues with top bar
$topbar-breakpoint: #{lower-bound($medium-range)} !default; // Change to 9999px for always mobile layout
$topbar-media-query: "#{$screen} and (min-width:#{lower-bound($topbar-breakpoint)})";
// Top-bar input styles
$topbar-input-height: rem-calc(28) !default;
// Divider Styles
$topbar-divider-border-bottom: solid 1px scale-color($topbar-bg-color, $lightness: 13%) !default;
$topbar-divider-border-top: solid 1px scale-color($topbar-bg-color, $lightness: -50%) !default;
// Sticky Class
$topbar-sticky-class: ".sticky" !default;
$topbar-arrows: true !default; //Set false to remove the triangle icon from the menu item
$topbar-dropdown-arrows: true !default; //Set false to remove the \00bb >> text from dropdown subnavigation li
// Accessibility mixins for hiding and showing the menu dropdown items
@mixin topbar-hide-dropdown {
    // Makes an element visually hidden by default, but visible when focused.
    display: block;
    @include element-invisible();
}

@mixin topbar-show-dropdown {
    display: block;
    @include element-invisible-off();
    position: absolute !important; // Reset the position from static to absolute
}

@include exports("top-bar") {
    @if $include-html-top-bar-classes {
        // Used to provide media query values for javascript components.
        // This class is generated despite the value of $include-html-top-bar-classes
        // to ensure width calculations work correctly.
        meta.foundation-mq-topbar {
            font-family: "/" + unquote($topbar-media-query) + "/";
            width: $topbar-breakpoint;
        }
        /* Wrapped around .top-bar to contain to grid width */
        .contain-to-grid {
            width: 100%;
            background: $topbar-bg;
            .top-bar {
                margin-bottom: $topbar-margin-bottom;
            }
        }
        // Wrapped around .top-bar to make it stick to the top
        .fixed {
            width: 100%;
            #{$default-float}: 0;
            position: fixed;
            top: 0;
            z-index: 99;
            &.expanded:not(.top-bar) {
                overflow-y: auto;
                height: auto;
                width: 100%;
                max-height: 100%;
                .title-area {
                    position: fixed;
                    width: 100%;
                    z-index: 99;
                }
                // Ensure you can scroll the menu on small screens
                .top-bar-section {
                    z-index: 98;
                    margin-top: $topbar-height;
                }
            }
        }
        .top-bar {
            overflow: hidden;
            height: $topbar-height;
            line-height: $topbar-height;
            position: relative;
            background: $topbar-bg;
            margin-bottom: $topbar-margin-bottom;
            // Topbar Global list Styles
            ul {
                margin-bottom: 0;
                list-style: none;
            }
            .row {
                max-width: none;
            }
            form,
            input {
                margin-bottom: 0;
            }
            input {
                height: $topbar-input-height;
                padding-top: .35rem;
                padding-bottom: .35rem;
                font-size: $topbar-button-font-size;
            }
            .button,
            button {
                padding-top: .35rem + rem-calc(1);
                padding-bottom: .35rem + rem-calc(1);
                margin-bottom: 0;
                font-size: $topbar-button-font-size;
                // position: relative;
                // top: -1px;
                // Corrects a slight misalignment when put next to an input field
                @media #{$small-only} {
                    position: relative;
                    top: -1px;
                }
            }
            // Title Area
            .title-area {
                position: relative;
                margin: 0;
            }
            .name {
                height: $topbar-height;
                margin: 0;
                font-size: $rem-base;
                // @if $topbar-menu-icon-position == $default-float {float: $opposite-direction;}
                h1,
                h2,
                h3,
                h4,
                p,
                span {
                    line-height: $topbar-height;
                    font-size: $topbar-title-font-size;
                    margin: 0;
                    a {
                        font-weight: $topbar-title-weight;
                        color: $topbar-link-color;
                        width: 75%;
                        display: block;
                        padding: 0 $topbar-link-padding;
                    }
                }
            }
            // Menu toggle button on small devices
            .toggle-topbar {
                position: absolute;
                #{$topbar-menu-icon-position}: 0;
                top: 0;
                a {
                    color: $topbar-link-color;
                    text-transform: $topbar-menu-link-transform;
                    font-size: $topbar-menu-link-font-size;
                    font-weight: $topbar-menu-link-weight;
                    position: relative;
                    display: block;
                    padding: 0 $topbar-link-padding;
                    height: $topbar-height;
                    line-height: $topbar-height;
                }
                // Adding the class "menu-icon" will add the 3-line icon people love and adore.
                &.menu-icon {
                    top: 50%;
                    margin-top: -16px;
                    a {
                        @if $text-direction==rtl {
                            text-indent: -58px;
                        }
                        height: 34px;
                        line-height: 33px;
                        padding: 0 $topbar-link-padding+rem-calc(25) 0 $topbar-link-padding;
                        color: $topbar-menu-link-color;
                        position: relative;
                        & {
                            // @include hamburger icon
                            //
                            // We use this to create the icon with three lines aka the hamburger icon, the menu-icon or the navicon
                            // $width - Width of hamburger icon
                            // $left - If false, icon will be centered horizontally || explicitly set value in rem
                            // $top - If false, icon will be centered vertically || explicitly set value in rem
                            // $thickness - thickness of lines in hamburger icon, set value in px
                            // $gap - spacing between the lines in hamburger icon, set value in px
                            // $color - icon color
                            // $hover-color - icon color during hover, here it isn't set b/c it would override $topbar-menu-icon-color-toggled
                            // $offcanvas - Set to false of @include in topbar
                            @include hamburger(16px, false, 0, 1px, 6px, $topbar-menu-icon-color, "", false);
                        }
                    }
                }
            }
            // Change things up when the top-bar is expanded
            &.expanded {
                height: auto;
                background: transparent;
                .title-area {
                    background: $topbar-bg;
                }
                .toggle-topbar {
                    a {
                        color: $topbar-menu-link-color-toggled;
                        span::after {
                            // Shh, don't tell, but box-shadows create the menu icon :)
                            // Change the color of the bars when the menu is expanded, using given thickness from hamburger() above
                            box-shadow: 0 0 0 1px $topbar-menu-icon-color-toggled, 0 7px 0 1px $topbar-menu-icon-color-toggled, 0 14px 0 1px $topbar-menu-icon-color-toggled;
                        }
                    }
                }
            }
        }
        // Right and Left Navigation that stacked by default
        .top-bar-section {
            #{$default-float}: 0;
            position: relative;
            width: auto;
            @include single-transition($default-float, $topbar-transition-speed);
            ul {
                padding: 0;
                width: 100%;
                height: auto;
                display: block;
                font-size: $rem-base;
                margin: 0;
            }
            .divider,
            [role="separator"] {
                border-top: $topbar-divider-border-top;
                clear: both;
                height: 1px;
                width: 100%;
            }
            ul li {
                background: $topbar-dropdown-bg;
                &>a {
                    display: block;
                    width: 100%;
                    color: $topbar-link-color;
                    padding: 12px 0 12px 0;
                    padding-#{$default-float}: $topbar-link-padding;
                    font-family: $topbar-link-font-family;
                    font-size: $topbar-link-font-size;
                    font-weight: $topbar-link-weight;
                    text-transform: $topbar-link-text-transform;
                    &.button {
                        font-size: $topbar-link-font-size;
                        padding-#{$opposite-direction}: $topbar-link-padding;
                        padding-#{$default-float}: $topbar-link-padding;
                        @include button-style($bg: $primary-color);
                    }
                    &.button.secondary {
                        @include button-style($bg: $secondary-color);
                    }
                    &.button.success {
                        @include button-style($bg: $success-color);
                    }
                    &.button.alert {
                        @include button-style($bg: $alert-color);
                    }
                    &.button.warning {
                        @include button-style($bg: $warning-color);
                    }
                }
                >button {
                    font-size: $topbar-link-font-size;
                    padding-#{$opposite-direction}: $topbar-link-padding;
                    padding-#{$default-float}: $topbar-link-padding;
                    @include button-style($bg: $primary-color);
                    &.secondary {
                        @include button-style($bg: $secondary-color);
                    }
                    &.success {
                        @include button-style($bg: $success-color);
                    }
                    &.alert {
                        @include button-style($bg: $alert-color);
                    }
                    &.warning {
                        @include button-style($bg: $warning-color);
                    }
                }
                // Apply the hover link color when it has that class
                &:hover:not(.has-form)>a {
                    background-color: $topbar-link-bg-color-hover;
                    @if ($topbar-link-bg-hover) {
                        background: $topbar-link-bg-hover;
                    }
                    color: $topbar-link-color-hover;
                }
                // Apply the active link color when it has that class
                &.active>a {
                    background: $topbar-link-bg-active;
                    color: $topbar-link-color-active;
                    &:hover {
                        background: $topbar-link-bg-active-hover;
                        color: $topbar-link-color-active-hover;
                    }
                }
            }
            // Add some extra padding for list items contains buttons
            .has-form {
                padding: $topbar-link-padding;
            }
            // Styling for list items that have a dropdown within them.
            .has-dropdown {
                position: relative;
                &>a {
                    &:after {
                        @if ($topbar-arrows) {
                            @include css-triangle($topbar-dropdown-toggle-size, rgba($topbar-dropdown-toggle-color, $topbar-dropdown-toggle-alpha), $default-float);
                        }
                        margin-#{$opposite-direction}: $topbar-link-padding;
                        //margin-top: -($topbar-dropdown-toggle-size / 2) - 2;
                        margin-top: -(math.div($topbar-dropdown-toggle-size, 2)) - 2;
                        position: absolute;
                        top: 50%;
                        #{$opposite-direction}: 0;
                    }
                }
                &.moved {
                    position: static;
                    &>.dropdown {
                        @include topbar-show-dropdown();
                        width: 100%;
                    }
                    &>a:after {
                        display: none;
                    }
                }
            }
            // Styling elements inside of dropdowns
            .dropdown {
                padding: 0;
                position: absolute;
                #{$default-float}: 100%;
                top: 0;
                z-index: 99;
                @include topbar-hide-dropdown();
                li {
                    width: 100%;
                    height: auto;
                    a {
                        font-weight: $topbar-dropdown-link-weight;
                        padding: 8px $topbar-link-padding;
                        &.parent-link {
                            font-weight: $topbar-link-weight;
                        }
                    }
                    &.title h5,
                    &.parent-link {
                        // Back Button
                        margin-bottom: 0;
                        margin-top: 0;
                        font-size: $topbar-back-link-size;
                        a {
                            color: $topbar-link-color;
                            // line-height: ($topbar-height / 2);
                            display: block;
                            &:hover {
                                background: none;
                            }
                        }
                    }
                    &.has-form {
                        padding: 8px $topbar-link-padding;
                    }
                    .button,
                    button {
                        top: auto;
                    }
                }
                label {
                    padding: 8px $topbar-link-padding 2px;
                    margin-bottom: 0;
                    text-transform: $topbar-dropdown-label-text-transform;
                    color: $topbar-dropdown-label-color;
                    font-weight: $topbar-dropdown-label-font-weight;
                    font-size: $topbar-dropdown-label-font-size;
                }
            }
        }
        .js-generated {
            display: block;
        }
        // Top Bar styles intended for screen sizes above the breakpoint.
        @media #{$topbar-media-query} {
            .top-bar {
                background: $topbar-bg;
                @include clearfix;
                overflow: visible;
                .toggle-topbar {
                    display: none;
                }
                .title-area {
                    float: $default-float;
                }
                .name h1 a,
                .name h2 a,
                .name h3 a,
                .name h4 a,
                .name h5 a,
                .name h6 a {
                    width: auto;
                }
                input,
                .button,
                button {
                    font-size: rem-calc(14);
                    position: relative;
                    height: $topbar-input-height;
                    //top: (($topbar-height - $topbar-input-height) / 2);
                    top: (math.div($topbar-height - $topbar-input-height, 2));
                }
                &.expanded {
                    background: $topbar-bg;
                }
            }
            .contain-to-grid .top-bar {
                max-width: $row-width;
                margin: 0 auto;
                margin-bottom: $topbar-margin-bottom;
            }
            .top-bar-section {
                @include single-transition(none, 0, 0);
                #{$default-float}: 0 !important;
                ul {
                    width: auto;
                    height: auto !important;
                    display: inline;
                    li {
                        float: $default-float;
                        .js-generated {
                            display: none;
                        }
                    }
                }
                li {
                    &.hover {
                        >a:not(.button) {
                            background-color: $topbar-link-bg-color-hover;
                            @if ($topbar-link-bg-hover) {
                                background: $topbar-link-bg-hover;
                            }
                            color: $topbar-link-color-hover;
                        }
                    }
                    &:not(.has-form) {
                        a:not(.button) {
                            padding: 0 $topbar-link-padding;
                            line-height: $topbar-height;
                            background: $topbar-link-bg;
                            &:hover {
                                background-color: $topbar-link-bg-color-hover;
                                @if ($topbar-link-bg-hover) {
                                    background: $topbar-link-bg-hover;
                                }
                            }
                        }
                    }
                    &.active:not(.has-form) {
                        a:not(.button) {
                            padding: 0 $topbar-link-padding;
                            line-height: $topbar-height;
                            color: $topbar-link-color-active;
                            background: $topbar-link-bg-active;
                            &:hover {
                                background: $topbar-link-bg-active-hover;
                                color: $topbar-link-color-active-hover;
                            }
                        }
                    }
                }
                .has-dropdown {
                    @if($topbar-arrows) {
                        &>a {
                            padding-#{$opposite-direction}: $topbar-link-padding + $topbar-link-dropdown-padding !important;
                            &:after {
                                @include css-triangle($topbar-dropdown-toggle-size, rgba($topbar-dropdown-toggle-color, $topbar-dropdown-toggle-alpha), top);
                                //margin-top: -($topbar-dropdown-toggle-size / 2);
                                margin-top: -(math.div($topbar-dropdown-toggle-size, 2));
                                //top: ($topbar-height / 2);
                                top: (math.div($topbar-height, 2));
                            }
                        }
                    }
                    &.moved {
                        position: relative;
                        &>.dropdown {
                            @include topbar-hide-dropdown();
                        }
                    }
                    &.hover,
                    &.not-click:hover {
                        &>.dropdown {
                            @include topbar-show-dropdown();
                        }
                    }
                    >a:focus+.dropdown {
                        @include topbar-show-dropdown();
                    }
                    .dropdown li.has-dropdown {
                        &>a {
                            @if ($topbar-dropdown-arrows) {
                                &:after {
                                    border: none;
                                    top: 1.125rem;
                                    margin-top: -1px;
                                    #{$opposite-direction}: 0;
                                    line-height: 1.2;
                                    transform: rotate(270deg);
                                    content: ''; 
                                }
                            }
                        }
                    }
                }
                .dropdown {
                    #{$default-float}: 0;
                    top: auto;
                    background: transparent;
                    min-width: 100%;
                    li {
                        a {
                            color: $topbar-dropdown-link-color;
                            line-height: $topbar-height;
                            white-space: nowrap;
                            padding: 12px $topbar-link-padding;
                            background: $topbar-dropdown-link-bg;
                        }
                        &:not(.has-form):not(.active) {
                            &>a:not(.button) {
                                color: $topbar-dropdown-link-color;
                                background: $topbar-dropdown-link-bg;
                            }
                            &:hover>a:not(.button) {
                                color: $topbar-dropdown-link-color-hover;
                                background-color: $topbar-link-bg-color-hover;
                                @if ($topbar-dropdown-link-bg-hover) {
                                    background: $topbar-dropdown-link-bg-hover;
                                }
                            }
                        }
                        label {
                            white-space: nowrap;
                            background: $topbar-dropdown-label-bg;
                        }
                        // Second Level Dropdowns
                        .dropdown {
                            #{$default-float}: 100%;
                            top: 0;
                        }
                    }
                }
                &>ul>.divider,
                &>ul>[role="separator"] {
                    border-bottom: none;
                    border-top: none;
                    border-#{$opposite-direction}: $topbar-divider-border-bottom;
                    clear: none;
                    height: $topbar-height;
                    width: 0;
                }
                .has-form {
                    background: $topbar-link-bg;
                    padding: 0 $topbar-link-padding;
                    height: $topbar-height;
                }
                // Position overrides for ul.right and ul.left
                .#{$opposite-direction} {
                    li .dropdown {
                        #{$default-float}: auto;
                        #{$opposite-direction}: 0;
                        li .dropdown {
                            #{$opposite-direction}: 100%;
                        }
                    }
                }
                .#{$default-float} {
                    li .dropdown {
                        #{$opposite-direction}: auto;
                        #{$default-float}: 0;
                        li .dropdown {
                            #{$default-float}: 100%;
                        }
                    }
                }
            }
            // Degrade gracefully when Javascript is disabled. Displays dropdown and changes
            // background & text color on hover.
            .no-js .top-bar-section {
                ul li {
                    // Apply the hover link color when it has that class
                    &:hover>a {
                        background-color: $topbar-link-bg-color-hover;
                        @if ($topbar-link-bg-hover) {
                            background: $topbar-link-bg-hover;
                        }
                        color: $topbar-link-color-hover;
                    }
                    // Apply the active link color when it has that class
                    &:active>a {
                        background: $topbar-link-bg-active;
                        color: $topbar-link-color-active;
                    }
                }
                .has-dropdown {
                    &:hover {
                        &>.dropdown {
                            @include topbar-show-dropdown();
                        }
                    }
                    >a:focus+.dropdown {
                        @include topbar-show-dropdown();
                    }
                }
            }
        }
    }
    @media screen and (-webkit-min-device-pixel-ratio:0) {
        .top-bar.expanded .top-bar-section {
            .has-dropdown.moved>.dropdown {
                clip: initial;
            }
            .dropdown {
                clip: initial;
            }
            // This was needed as parent ul's had padding, which the clip: was allowing content to peak through
            .has-dropdown:not(.moved)>ul {
                padding: 0;
            }
        }
    }
    @media screen and (-webkit-min-device-pixel-ratio:0) {
        .top-bar.expanded .top-bar-section {
            .has-dropdown.moved>.dropdown {
                clip: initial;
            }
            .dropdown {
                clip: initial;
            }
            // This was needed as parent ul's had padding, which the clip: was allowing content to peak through
            .has-dropdown:not(.moved)>ul {
                padding: 0;
            }
        }
    }
}