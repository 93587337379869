// Cajas de los filtros
.select2-container {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle;
    color: rgba(0, 0, 0, 1);
    height: auto !important;
    .select2-selection--single {
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        /*height: 2.3125rem;*/
        user-select: none;
        -webkit-user-select: none;
        .select2-selection__rendered {
            /*display: block;*/
            overflow: hidden;
            padding-left: 8px;
            padding-right: 20px;
            text-overflow: ellipsis;
            font-size: 0.75rem;
        }
    }
    &[dir="rtl"] .select2-selection--single .select2-selection__rendered {
        padding-right: 8px;
        padding-left: 20px;
        font-size: 0.75rem;
    }
    .select2-selection--multiple {
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        /*min-height: 32px;*/
        user-select: none;
        -webkit-user-select: none;
        .select2-selection__rendered {
            display: inline-block;
            overflow: hidden;
            padding-left: 8px;
            text-overflow: ellipsis;
        }
    }
    .select2-search--inline {
        // float: none;
        border: 1px solid #ccc;
        margin: 0.5em 0 0 0;
        position: relative;
        width: 100%;
        border-radius: 4px;
        &:after {
            content: "\f134";
            font-family: uicons-regular-rounded !important;
            color: $azuloscuro;
            position: absolute;
            right: 8px;
            top: calc(50% - 12px);
            font-size: 16px;
        }
        .select2-search__field {
            border: none;
            font-size: 100%;
            line-height: 20px;
        }
    }
}

.select2-dropdown {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: 1051;
}

.select2-results {
    display: block;
}

.select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0;
}

.select2-results__option {
    padding: 6px;
    font-size: .75rem;
    user-select: none;
    -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
    cursor: pointer;
}

.select2-container--open .select2-dropdown {
    left: 0;
}

.select2-container--open .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.select2-search--dropdown {
    display: block;
    padding: 4px;
    .select2-search__field {
        padding: 0;
        width: 100%;
        box-sizing: border-box;
    }
    &.select2-search--hide {
        display: none;
    }
}

.select2-close-mask {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 99;
    background-color: #fff;
    filter: alpha(opacity=0);
}

.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #ccc;
    overflow: hidden;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: $gray5;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 1.5rem;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
    float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    left: 1px;
    right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #eee;
    cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow {
    transform: rotate(180deg);
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
    background-color: white;
    border: 1px solid #cccccc;
    cursor: text;
    overflow: hidden !important;
    height: auto !important;
    min-height: 35px;
    border-radius: 4px 0px 4px 4px;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    color: #999;
    margin-top: 5px;
    float: left;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 10px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: $blanco;
    border: 1px solid $blanco;
    cursor: default;
    margin-right: 5px;
    margin-top: 5px;
    font-size: 0.75rem;
    padding: 0 7px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    &:hover {
        background-color: #8a8a8a;
        border-color: #8a8a8a;
        color: white;
        span {
            color: white;
        }
    }
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: $gray3;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 6px;
    font-size: 15px;
    line-height: 1;
    float: none;
    border: 1px solid $gray3;
    padding: 0 3px;
    border-radius: 100%;
    width: 16px;
    height: 16px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #fff;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder {
    float: none;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: #eee;
    cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
    display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
    line-height: 34px;
}

.select2-container--default .select2-search--inline .select2-search__field {
    background: transparent;
    border: none;
    outline: 0;
}

.select2-container--default .select2-results>.select2-results__options {
    max-height: 200px;
    overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
    padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
    color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background: rgba(190, 190, 190, .2) url(../../../public/images/ico-tick.png) no-repeat 98% 5px;
    border-bottom: 1px solid white;
}

.select2-container--default .select2-results__option .select2-results__option {
    padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -2em;
    padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -3em;
    padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -4em;
    padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -5em;
    padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #ccc;
    color: #000;
}

.select2-container--default .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
}

.select2-container--classic .select2-selection--single {
    background-color: #f6f6f6;
    border: 1px solid #aaa;
    border-radius: 4px;
    outline: 0;
    background-image: -webkit-linear-gradient(top, #ffffff 50%, #eeeeee 100%);
    background-image: -o-linear-gradient(top, #ffffff 50%, #eeeeee 100%);
    background-image: linear-gradient(to bottom, #ffffff 50%, #eeeeee 100%);
    background-repeat: repeat-x;
    /*filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eeeeee', GradientType=0);*/
}

.select2-container--classic .select2-selection--single:focus {
    border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 23px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-right: 10px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: #ddd;
    border: none;
    border-left: 1px solid #aaa;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
    background-repeat: repeat-x;
    /*filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#cccccc', GradientType=0);*/
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
    float: left;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    border: none;
    border-right: 1px solid #aaa;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    left: 1px;
    right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
    border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: transparent;
    border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-image: -webkit-linear-gradient(top, #ffffff 0%, #eeeeee 50%);
    background-image: -o-linear-gradient(top, #ffffff 0%, #eeeeee 50%);
    background-image: linear-gradient(to bottom, #ffffff 0%, #eeeeee 50%);
    background-repeat: repeat-x;
    /*filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eeeeee', GradientType=0);*/
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-image: -webkit-linear-gradient(top, #eeeeee 50%, #ffffff 100%);
    background-image: -o-linear-gradient(top, #eeeeee 50%, #ffffff 100%);
    background-image: linear-gradient(to bottom, #eeeeee 50%, #ffffff 100%);
    background-repeat: repeat-x;
    /*filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0);*/
}

.select2-container--classic .select2-selection--multiple {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
    outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
    border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
    list-style: none;
    margin: 0;
    padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
    display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: none;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    color: #888;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #555;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    float: none;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
    border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
    outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
    outline: 0;
}

.select2-container--classic .select2-dropdown {
    background-color: white;
    border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
    border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
    border-top: none;
}

.select2-container--classic .select2-results>.select2-results__options {
    max-height: 200px;
    overflow-y: auto;
}

.select2-container--classic .select2-results__option[role=group] {
    padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
    color: grey;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
    background-color: #ccc;
    color: #000;
}

.select2-container--classic .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
    border-color: #5897fb;
}


/*fixes*/

@media screen and (min-width: $medio) {
    #formEmpresa_tipo+span.select2 {
        min-width: 40rem;
        height: 35px;
    }
}
